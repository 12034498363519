<template>
  <div
    class="Label"
    v-for="(item, name) in allLabels"
  >
    {{ name }}
    <q-tooltip
      v-if="showTooltip(item)"
      anchor="bottom start"
      self="top start"
    >
      <div
        v-for="label in item"
        :key="label.id"
      >
        <div v-text="label.text" />
      </div>
    </q-tooltip>
  </div>
</template>

<script setup lang="ts">
const props = defineProps({
  labels: {
    type: Object as PropType<Label[]>,
    required: true,
  },
});

// TODO: Utils!!
// const groupBy = function (xs: Record<any, any>[], key: string) {
//   return xs.reduce(function (rv, x) {
//     (rv[x[key]] = rv[x[key]] || []).push(x);
//     return rv;
//   }, {});
// };
const allLabels = computed(() => groupBy(props.labels, 'name'));

function showTooltip(label: Label[]) {
  return label.some((item) => !!item.text);
}
</script>

<style lang="scss" scoped>
.Label {
  border-radius: 16px;
  background: $accent-50;
  color: white;
  border: none;
  padding: 4px 24px;
}
</style>
