import { HOLIDAY_TYPES, ACCOMODATION_TYPES, FEATURES, QUALITY_LABELS, LABELS, DISCOUNTS, ACCOMODATION_SUB_FILTERS } from '~/config';

export function countVerfijnFilters(filters: VerfijnFilterValues) {
  return (
    (filters[ACCOMODATION_TYPES]?.length || 0) +
    (filters[HOLIDAY_TYPES]?.length || 0) +
    (filters[FEATURES]?.length || 0) +
    (filters[ACCOMODATION_SUB_FILTERS]?.length || 0) +
    (filters[DISCOUNTS]?.length || 0) +
    (filters[QUALITY_LABELS]?.length || 0) +
    (filters[LABELS]?.length || 0)
  );
}
