import landing from './nl/landing';
import login from './nl/login';
import general from './nl/general';
import detail from './nl/detail';
import account from './nl/account';
import validations from './nl/validations';
import booking from './nl/booking';
import owner from './nl/owner';

import ENlanding from './en/landing';
import ENlogin from './en/login';
import ENgeneral from './en/general';
import ENdetail from './en/detail';
import ENaccount from './en/account';
import ENvalidations from './en/validations';
import ENbooking from './en/booking';
import ENowner from './en/owner';

export default {
  nl: {
    landing,
    login,
    general,
    detail,
    account,
    validations,
    booking,
    owner,
  },
  en: {
    landing: ENlanding,
    login: ENlogin,
    general: ENgeneral,
    detail: ENdetail,
    account: ENaccount,
    validations: ENvalidations,
    booking: ENbooking,
    owner: ENowner,
  },
};
