import useStore from '~/store/store';

export default function locationMapping(location: { regionIds: number[]; environmentIds: number[] }) {
  const store = useStore();
  const locations: string[] = [];

  location.regionIds?.forEach((location: number) => {
    locations.push(store.regionsMapping[location]);
  });

  location.environmentIds?.forEach((location: number) => {
    locations.push(store.environmentMapping[location]);
  });

  return locations.join(', ');
}
