export default {
  landing: 'Ervaar Italie !!!',
  when: 'Wanneer wil je gaan?',
  where: 'Waar wil je heen?',
  amountGuests: 'Aantal gasten en slaapkamers',
  view: 'Bekijk accommodatie',

  filters: {
    where: {},

    when: {},

    amount: {
      adults: 'Volwassenen',
      babys: "Baby's",
      adultsAge: 'Leeftijd 12+',
      bedrooms: 'Slaapkamers',
      children: 'Kinderen',
      childrenAge: 'Leeftijd 3 tot en met 12',
      babysAge: 'Leeftijd 0 tot en met 2',
      beds: 'Bedden',
      sofaAccomodations: 'Toon accommodaties met sofabedden',
    },

    mobileModal: {
      location: 'Locatie',
      date: 'Datum',
      guests: 'Gasten',
      highlighted: 'Uitgelicht',
      region: 'Regio',
      lakes: 'Meren',
    },

    guestsAndRooms: 'Gasten en kamers',
    accomodationType: 'Accommodatietype',
    labels: 'Acties & specials',
    typeHoliday: 'Type vakantie',
    qualityLabel: 'Kwaliteitslabel',
    specials: 'Acties & Specials',
    price: 'Prijs per nacht',
    facilities: 'Voorzieningen',
    location: 'Locatie',
  },

  newsletter: {
    descr: 'Schrijf je in voor de nieuwsbrief',
    title: 'Blijf op de hoogte van de nieuwste accommodaties, acties, last minutes en tips van Ecco Italia!',
    button: 'Inschrijven',
  },

  footer: {
    usefulLinks: 'Handige links',
    contact: 'Contact',
    companyName: 'Ecco Italia',
    address: 'Overschieseweg 10 unit i',
    postalCodeCity: '3044 EE ROTTERDAM',
    city: 'ROTTERDAM',
    telephone: '+31 (0)10 – 820 85 21',
    mail: "info{'@'}eccoitalia.nl",
    // info: 'Handelsnaam van SmookItalia B.V.',
    lastNews: 'Krijg het laatste nieuws!',
    sgr: 'SGR Garantie',
    about: 'Over Ecco Italia',
  },
};
