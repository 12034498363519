import { defineStore } from 'pinia';
import { LANDING_PAGE_ID, ABOUT_PAGE_ID, QUICKLINKS_PAGE_ID, FOOTER_LINKS_PAGE_ID, DESTINATIONS_PAGE_ID } from '~/config';

interface Redirect {
  destination: string;
  permanent: boolean;
  source: string;
}

export default defineStore('elocusStore', () => {
  const { landing, footerLinks, quickLinks, about, slugsDatabase, setLanding, setQuickLinks, setAbout, setFooterLinks, setDestinations } =
    footerLinksHandler();

  const databank = ref({});

  const redirects = ref<Redirect[]>([]);
  const redirectTriggers = computed(() => redirects.value.map((item: any) => item?.source));
  const destinations = ref<ElocusNavigationItem[]>([]);

  function setRedirects(res: Redirect[]) {
    redirects.value = res;
  }

  function setDatabank(value: any) {
    databank.value = value;
  }

  function footerLinksHandler() {
    const landing = ref<ElocusPage[]>([]);
    const quickLinks = ref<ElocusPage[]>([]);
    const about = ref<ElocusPage[]>([]);
    const footerLinks = ref<ElocusPage[]>([]);

    // Soms doet een gebruiker een starting slash als slug, die worden hier gestript
    function stripStartingSlashes(pages: ElocusPage[]) {
      pages?.forEach((page: { content: { slug: string } }) => {
        page.content.slug = stripStartingSlashOfItem(page.content.slug);
      });
      return pages;
    }

    function stripStartingSlashOfItem(item: string) {
      if (item[0] === '/') {
        return item.slice(1);
      }
      return item;
    }

    async function setLanding(res?: ElocusPage[]) {
      landing.value = stripStartingSlashes(res);
    }

    async function setQuickLinks(res: ElocusPage[]) {
      quickLinks.value = stripStartingSlashes(res);
    }

    async function setAbout(res: ElocusPage[]) {
      about.value = stripStartingSlashes(res);
    }

    async function setFooterLinks(res: ElocusPage[]) {
      footerLinks.value = stripStartingSlashes(res);
    }

    function setDestinations(res: ElocusNavigationItem[]) {
      res.forEach((item) => {
        item.children.forEach((child) => {
          child.parentId = item.id;

          if (child.children) {
            child.children.forEach((subChild) => {
              subChild.parentId = child.id;
            });
          }
        });
      });
      destinations.value = res;
    }

    const slugsDatabase = computed(() => {
      const obj: Record<string, string> = {};
      landing.value?.forEach((item: ElocusPage) => (obj['/' + item.content.slug] = `${LANDING_PAGE_ID}/${item._id}`));
      quickLinks.value?.forEach((item: ElocusPage) => (obj['/' + item.content.slug] = `${QUICKLINKS_PAGE_ID}/${item._id}`));
      about.value?.forEach((item: ElocusPage) => (obj['/' + item.content.slug] = `${ABOUT_PAGE_ID}/${item._id}`));
      footerLinks.value?.forEach((item: ElocusPage) => (obj['/' + item.content.slug] = `${FOOTER_LINKS_PAGE_ID}/${item._id}`));
      destinations.value?.forEach((item: ElocusNavigationItem) => {
        if (item.children) {
          item.children.forEach((child) => {
            if (child.url) {
              obj['/' + stripStartingSlashOfItem(child.url)] = `${DESTINATIONS_PAGE_ID}/${child.id}`;
            }

            if (child.children) {
              child.children.forEach((subChild) => {
                obj['/' + stripStartingSlashOfItem(subChild.url)] = `${DESTINATIONS_PAGE_ID}/${subChild.id}`;
              });
            }
          });
        }
      });

      return obj;
    });

    return { quickLinks, about, landing, footerLinks, slugsDatabase, setLanding, setQuickLinks, setAbout, setFooterLinks, setDestinations };
  }

  return {
    databank,
    setDatabank,
    redirects,
    setRedirects,
    redirectTriggers,
    quickLinks,
    about,
    setLanding,
    setDestinations,
    destinations,
    setQuickLinks,
    setAbout,
    setFooterLinks,
    landing,
    slugsDatabase,
    footerLinks,
  };
});
