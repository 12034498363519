<template>
  <q-img
    v-if="src && isMounted"
    v-bind="imageProps"
    @error="hasError = true"
    class="Image"
  >
    <slot />

    <template v-slot:error>
      <div
        class="absolute-full flex flex-center error"
        v-if="hasError"
      >
        <q-icon name="icon-close" />
        Geen afbeelding beschikbaar
      </div>
    </template>
  </q-img>

  <q-skeleton
    v-else-if="!isMounted"
    class="Image"
  />

  <div v-else>
    <div class="flex flex-center error">
      <q-icon name="icon-close" />
      Geen afbeelding beschikbaar
    </div>
  </div>
</template>

<script setup>
const props = defineProps({
  src: {
    type: String,
    required: true,
  },
  alt: {
    type: String,
    default: 'Afbeelding',
  },
  fallbackSrc: {
    type: String,
    default: '', // Provide a default fallback image source here if needed
  },
});

const hasError = ref(false);
const imageProps = ref(props);
const isMounted = ref(false);

onMounted(() => {
  isMounted.value = true;
});
</script>

<style lang="scss" scoped>
.error {
  height: 100%;
  color: red;
}

.q-img {
  border-radius: 8px;
}
</style>
