export default {
  landing: 'Experience Italy!!!',
  when: 'When do you want to go?',
  where: 'Where do you want to go?',
  amountGuests: 'Number of guests and bedrooms',
  view: 'View accommodation',

  filters: {
    where: {},

    when: {},

    amount: {
      adults: 'Adults',
      babys: 'Babies',
      adultsAge: 'Age 12+',
      bedrooms: 'Bedrooms',
      children: 'Children',
      childrenAge: 'Age 3 to 12',
      babysAge: 'Age 0 to 2',
      beds: 'Beds',
      sofaAccomodations: 'Show accommodations with sofa beds',
    },

    mobileModal: {
      location: 'Location',
      date: 'Date',
      guests: 'Guests',
      highlighted: 'Highlighted',
      region: 'Region',
      lakes: 'Lakes',
    },

    guestsAndRooms: 'Guests and rooms',
    accomodationType: 'Accommodation type',
    labels: 'Actions & specials',
    typeHoliday: 'Type of holiday',
    qualityLabel: 'Quality label',
    specials: 'Actions & Specials',
    price: 'Price per night',
    facilities: 'Facilities',
    location: 'Location',
  },

  newsletter: {
    descr: 'Subscribe to the newsletter',
    title: 'Stay informed about the latest accommodations, promotions, last-minute deals, and tips from Ecco Italia!',
    button: 'Subscribe',
  },

  footer: {
    usefulLinks: 'Useful links',
    contact: 'Contact',
    companyName: 'Ecco Italia',
    address: 'Overschieseweg 10 unit i',
    postalCodeCity: '3044 EE ROTTERDAM',
    city: 'ROTTERDAM',
    telephone: '+31 (0)10 – 820 85 21',
    mail: "info{'@'}eccoitalia.nl",
    // info: 'Trade name of SmookItalia B.V.',
    lastNews: 'Get the latest news!',
    sgr: 'SGR Guarantee',
    about: 'About Ecco Italia',
  },
};
