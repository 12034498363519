export default {
  // login
  logIn: 'Inloggen',
  forgotPassword: 'Wachtwoord vergeten',
  loginGoogle: 'Inloggen met Google',
  loginFacebook: 'Inloggen met Facebook',
  register: 'Registreren',
  stayLoggedIn: 'Ingelogd blijven',
  noAccount: 'Nog geen account?',
  emailSent: 'Er is een e-mail verstuurd om uw account te activeren.',

  // Forgot passward
  enterMail: 'Voer uw e-mail in zodat een mail verstuurd kan worden met een link om uw wachtwoord te resetten.',
  reset: 'Reset',
  submit: 'Instellen',
  errorMessagePassword: 'Wachtwoord bewerken niet gelukt',

  // Verify email
  verifyMail: 'E-mail verifiëren',
  accountActivated: 'Uw account is geactiveerd. Klik op onderstaande knop om in te loggen.',
  toLogin: 'Naar login',

  // not verified account
  notVerified: 'We hebben je een email gestuurd ter verificatie, controleer ook eventueel je ongewenste mail/spamfolder.',

  modalTitles: {
    register: 'Registreren',
    accountCreated: 'Account aangemaakt',
    emailVerify: 'E-mail verifiëren',
    Login: 'Inloggen',
    forgotPassword: 'Wachtwoord vergeten',
    editPassword: 'Wachtwoord bewerken',
    setPassword: 'Wachtwoord instellen',
    emailVerified: 'Email geverifieerd',
    emailNotVerified: 'Email nog niet geverifieerd',
  },
};
