<template>
  <div>
    <div class="q-mt-md">
      <TextInput
        :label-text="t('account.editAccount.password')"
        v-model="data.password"
        :type="showPassword ? 'text' : 'password'"
        bottom-slots
        :error="!!v$.password.$errors.length"
        :error-message="!!v$.password.$errors.length ? v$.password.$errors[0].$message : ''"
      >
        <template #append>
          <q-icon
            :name="showPassword ? 'visibility_off' : 'visibility'"
            class="cursor-pointer"
            @click="showPassword = !showPassword"
          />
        </template>
      </TextInput>
    </div>

    <div class="q-mt-md">
      <TextInput
        :label-text="t('account.editAccount.repeatPassword')"
        v-model="data.passwordConfirm"
        :type="showConfirmPassword ? 'text' : 'password'"
        bottom-slots
        :error="!!v$.passwordConfirm.$errors.length"
        :error-message="!!v$.passwordConfirm.$errors.length ? v$.passwordConfirm.$errors[0].$message : ''"
      >
        <template #append>
          <q-icon
            :name="showConfirmPassword ? 'visibility_off' : 'visibility'"
            class="cursor-pointer"
            @click="showConfirmPassword = !showConfirmPassword"
          />
        </template>
      </TextInput>
    </div>
    <q-btn
      :loading="isLoading"
      class="q-mt-lg"
      @click="resetPassword"
      color="primary"
      :disable="!data.password || !data.passwordConfirm"
    >
      {{ isExistingUser ? t('login.reset') : t('login.submit') }}
    </q-btn>
  </div>
</template>

<script setup lang="ts">
import { useVuelidate } from '@vuelidate/core';
import { required, minLength, maxLength, passwordValidation, sameAs } from '~/utils/validations';
import useAuthStore from '~/store/auth';
import { useI18n } from 'vue-i18n';

const emit = defineEmits(['password-changed']);
const authStore = useAuthStore();
const route = useRoute();
const { t } = useI18n();
const isLoading = ref(false);
const isExistingUser = !!route.query.verifyandsetpassword ? false : true;

const data = reactive({
  token: isExistingUser
    ? encodeURIComponent(route.query.resetpassword?.toString() || '')
    : encodeURIComponent(route.query.verifyandsetpassword?.toString() || ''),
  password: '',
  passwordConfirm: '',
});

const rules = computed(() => ({
  password: {
    required,
    minLength: minLength(5),
    maxLength: maxLength(25),
    passwordValidation,
    $autoDirty: true,
  },
  passwordConfirm: { required, sameAsPassword: sameAs(data.password), $autoDirty: true },
}));

const v$ = useVuelidate(rules, data);
const showPassword = ref(false);
const showConfirmPassword = ref(false);

async function resetPassword() {
  isLoading.value = true;
  const isValid = await v$.value.$validate();
  if (!isValid) {
    isLoading.value = false;
    return;
  }

  try {
    const result: any = await fetchData('/authentication/resetpassword', { method: 'put', body: data });

    setToast({
      type: 'positive',
      message: 'Wachtwoord aangepast',
    });
    if (result.user && isExistingUser) {
      // TODO: Endpoint geeft nog geen token terug, zou de flow verbeteren
      setUser(result.user);
    }
    emit('password-changed');
  } catch (e) {
    setToast({
      type: 'negative',
      message: e.response?.data?.detail || t('login.errorMessagePassword'),
    });
  }

  isLoading.value = false;
}
</script>
