export default {
  save: 'Save',
  saved: 'Saved',

  desktopNavbar: {
    landing: 'Offers',
    inquiry: 'We search for you',
    upcomingTrips: 'Travel advice',
    accountLoggedIn: 'My account',
    accountNotLoggedIn: 'Log In',
    contact: 'Contact',
  },

  mobileNavBar: {
    landing: 'Offers',
    inquiry: 'Assistance',
    upcomingTrips: 'Travel advice',
    accountLoggedIn: 'Account',
    accountNotLoggedIn: 'Log in',
    contact: 'Contact',
  },
};
