<template>
  <div class="">
    <div class="heading2 q-mb-lg">
      {{ content.titel }}
    </div>

    <div class="row q-col-gutter-lg">
      <div
        v-for="rel in related"
        class="col-xs-12"
        :class="flexClass"
        :key="rel?._id"
      >
        <router-link
          v-if="rel.content && rel.content.slug"
          :to="addStartingSlash(rel.content.slug)"
        >
          <q-img :src="getImageUrl(rel.content.afbeelding, imgSize, imgSize, true)">
            <template v-slot:error>
              <div class="absolute-full flex flex-center">Geen afbeelding beschikbaar</div>
            </template>
            <div class="imgContent">
              <span
                class="heading3 heading q-mt-md"
                v-text="rel.content.titel"
              />

              <q-btn color="primary"> Ontdek </q-btn>
            </div>
          </q-img>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
const props = defineProps({
  content: {
    type: Object as PropType<GerelateerdePaginasContentblock>,
    required: true,
  },
});

const pages = computed(() => props.content.landingspaginaS.split(', '));
const imgSize = computed(() => {
  if (pages.value.length === 1) return 1200;
  if (pages.value.length === 2) return 600;
  if (pages.value.length === 4) return 500;
  return 600;
});
const flexClass = computed(() => {
  if (pages.value.length === 1) return 'col-md-12';
  if (pages.value.length === 2) return 'col-md-6';
  if (pages.value.length === 4) return 'col-md-3';
  return 'col-md-4';
});

const related = await Promise.all(pages.value.map(async (id: number): Promise<Object> => await fetchData(`/api/page/2075/${id}`)));

function addStartingSlash(slug: string): string {
  return slug.startsWith('/') ? slug : `/${slug}`;
}
</script>

<style lang="scss" scoped>
@import '~/styles/variables.sass';

.q-img {
  border-radius: 16px;
  width: 100%;
  height: 280px;
}

.imgContent {
  width: 100%;
  position: absolute;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  background: transparent;
}

.heading {
  color: white;
  text-align: center;
  text-shadow: 0px 1px 1px rgba(0, 0, 0, 0.25), 0px 1px 6.9px rgba(0, 0, 0, 0.25);
}

.imageContainer {
  border-radius: 8px;
  background-repeat: no-repeat;
}

@media (max-width: $screen-xs) {
  img {
    float: left;
  }
}
</style>
