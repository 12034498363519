export default {
  save: 'Opslaan',
  saved: 'Opgeslagen',

  desktopNavbar: {
    landing: 'Zoek & Boek',
    inquiry: 'Wij zoeken voor u',
    upcomingTrips: 'Reisadviezen',
    accountLoggedIn: 'Mijn account',
    accountNotLoggedIn: 'Inloggen',
    contact: 'Contact',
  },

  mobileNavBar: {
    landing: 'Aanbod',
    inquiry: 'Zoekhulp',
    upcomingTrips: 'Reisadviezen',
    accountLoggedIn: 'Account',
    accountNotLoggedIn: 'Inloggen',
    contact: 'Contact',
  },
};
