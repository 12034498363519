import useStore from '~/store/store';

export function favoriteHandler() {
  const store = useStore();
  const favorites = computed(() => store.favorites.map(({ id }) => id));

  function handleFavorite(id: number, name: string) {
    if (favorites.value.includes(id)) {
      store.deleteFavorite(id);
    } else {
      store.addFavorite(id, name);
    }
  }

  function isFavorited(id: number) {
    return favorites.value.includes(id);
  }
  return {
    handleFavorite,
    isFavorited,
  };
}
