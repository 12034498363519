import { defineNuxtPlugin } from '#app';
import * as Sentry from '@sentry/vue';

export default defineNuxtPlugin((nuxtApp) => {
  const { vueApp } = nuxtApp;
  Sentry.init({
    app: [vueApp],
    dsn: 'https://a5575fac9b2ca9f96e64264e4c5a203a@o4505719293018112.ingest.sentry.io/4505827684843520',
    logErrors: false, // Note that this doesn't seem to work with nuxt 3
    tracesSampleRate: 1.0, // Sentry recommends adjusting this value in production
    debug: false, // Enable debug mode
    // environment: config.ENVIRONMENT || 'dev', // Set environment
    // The following enables exeptions to be logged to console despite logErrors being set to false (preventing them from being passed to the default Vue err handler)
    beforeSend(event, hint) {
      // Check if it is an exception, and if so, log it.
      if (event.exception) {
        console.error(`[Exeption handled by Sentry]: (${hint.originalException})`, { event, hint });
      }
      // Continue sending to Sentry
      return event;
    },
  });

  vueApp.mixin(Sentry.createTracingMixins({ trackComponents: true, timeout: 2000, hooks: ['activate', 'mount', 'update'] }));
  Sentry.attachErrorHandler(vueApp, {
    logErrors: false,
    attachProps: true,
    trackComponents: true,
    timeout: 2000,
    hooks: ['activate', 'mount', 'update'],
  });

  return {
    provide: {
      sentrySetContext: (n, context) => Sentry.setContext(n, context),
      sentrySetUser: (user) => Sentry.setUser(user),
      sentrySetTag: (tagName, value) => Sentry.setTag(tagName, value),
      sentryAddBreadcrumb: (breadcrumb) => Sentry.addBreadcrumb(breadcrumb),
    },
  };
});
