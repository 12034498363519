export default {
  // Heel booking is stepper, dus miss is dit overbodig om alles te wrappen in stepper obj
  stepper: {
    persons: 'Persoonsgegevens',
    travelCompany: 'Reisgezelschap',
    facilities: 'Services',
    paymentInfo: 'Betaalinformatie',
    summary: 'Samenvatting',
  },
};
