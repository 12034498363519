<template>
  <div class="LoginPage">
    <form @submit.prevent="login">
      <div class="q-mt-md">
        <TextInput
          :label-text="t('account.editAccount.emailAddress')"
          v-model="data.email"
          bottom-slots
          :error="!!v$.email.$errors.length"
          :error-message="!!v$.email.$errors.length ? v$.email.$errors[0].$message : ''"
        />
      </div>

      <div class="q-mt-md">
        <TextInput
          :label-text="t('account.editAccount.password')"
          v-model="data.password"
          :type="showPassword ? 'text' : 'password'"
          bottom-slots
          :error="!!v$.password.$errors.length"
          :error-message="!!v$.password.$errors.length ? v$.password.$errors[0].$message : ''"
        >
          <template #append>
            <q-icon
              :name="showPassword ? 'visibility_off' : 'visibility'"
              class="cursor-pointer"
              @click="showPassword = !showPassword"
            />
          </template>
        </TextInput>
      </div>

      <q-checkbox
        dense
        class="q-mt-md"
        v-model="stayLoggedIn"
        color="secondary"
        :label="t('login.stayLoggedIn')"
      />

      <div class="q-mt-md">
        <q-btn
          :loading="isLoading"
          color="primary"
          type="submit"
        >
          {{ t('login.logIn') }}
        </q-btn>

        <q-btn
          flat
          @click="emit('forgot-password')"
          v-t="'login.forgotPassword'"
        />
      </div>

      <div v-if="!loginOnly">
        <hr class="q-mt-xl" />

        <div class="q-mt-md">
          <span
            v-t="'login.noAccount'"
            class="q-mr-sm"
          />
          <span
            class="textLink pointer"
            @click="emit('register')"
            v-t="'login.register'"
          >
          </span>
        </div>
      </div>
    </form>
  </div>
</template>

<script setup lang="ts">
import { useI18n } from 'vue-i18n';
import { useVuelidate } from '@vuelidate/core';
import { required, email } from '~/utils/validations';
import useAuthStore from '~/store/auth';
const { t } = useI18n();

const props = defineProps({
  // LoginOnly wordt gebruikt wanneer dit component alleen wordt gebruikt om in te loggen
  // Dus niet registreren, aanmaken etc.
  loginOnly: {
    type: Boolean,
    default: false,
  },

  // Booking heeft een andere endpoint met andere config..
  isBooking: {
    type: Boolean,
    default: false,
  },
});

const authStore = useAuthStore();

const emit = defineEmits(['register', 'logged-in', 'forgot-password', 'not-verified']);

const data = reactive({
  email: '',
  password: '',
});

const rules = computed(() => ({
  email: { required, email, $autoDirty: true },
  password: { required, $autoDirty: true },
}));

const v$ = useVuelidate(rules, data);

const showPassword = ref(false);
const stayLoggedIn = ref(false);
const isLoading = ref(false);

async function login() {
  const isValid = await v$.value.$validate();
  if (!isValid) {
    return;
  }
  try {
    isLoading.value = true;
    const apiEndpoint = props.isBooking ? '/booking/start/login' : '/authentication/login';
    const res: LoginResponse = (await fetchData(apiEndpoint, { method: 'post', body: data }, true, true)) as LoginResponse;

    isLoading.value = false;
    if (res.token) {
      const cookie = useCookie('token', { expires: new Date(res.token.expiration) });
      cookie.value = res.token.value;
      authStore.setToken(res.token.value);
      setUser(res.user);

      emit('logged-in');
    } else if (res.message) {
      setToast({
        type: 'negative',
        message: res.message,
      });
    } else {
      if (res.error?.errorCode == 10) {
        emit('not-verified');
      }
    }
  } catch (e) {
    setToast({
      type: 'negative',
      message: e.response?.data?.detail || 'Inloggen niet gelukt',
    });
  }
}
</script>

<style scoped lang="scss">
.LoginPage {
  height: 100%;
  width: 350px;
}

.loginContainer {
  max-width: 550px;
  margin: auto;
  margin-top: 10%;
  top: 50%;
  padding: 100px;
}

hr {
  border: 1x solid #a3a8ad;
}

.socialLogin {
  color: black;
}

@media (max-width: $screen-xs) {
  .LoginPage {
    width: 100%;
  }
}
</style>
