export default {
  categories: {
    description: 'Beschrijving',
    facilities: 'Voorzieningen ',
    location: 'De locatie',
    availability: 'Beschikbaarheid',
    doNotMiss: 'Niet te missen',
    reviews: 'Reviews',
  },
};
