import useAuthStore from '~/store/auth';

export default defineNuxtPlugin(async () => {
  try {
    const c = useCookie<string>('token');
    const booking = useCookie<User>('jfi_bookingUser');

    if (c.value) {
      await useClientFetch('/authentication/profile', {
        headers: {
          Authorization: `bearer ${c.value}`,
        },
      }).then((data) => {
        const authStore = useAuthStore();

        if (data.user) {
          authStore.setStoreUser(data.user);
        } else if (booking.value) {
          const token = booking.value;

          try {
            authStore.setStoreUser(token);
          } catch (e) {
            console.log(e);
          }
        }
        authStore.setToken(c.value);
      });
    }
  } catch (e) {
    console.log(e);
  }
});
