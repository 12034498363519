<template>
  <div>
    <div
      class="AccomodationFilters filters row q-col-gutter-md items-end"
      :class="{ 'q-col-gutter-md': !isMobile }"
      id="filters"
    >
      <TextInput
        readonly
        class="whereSelect col-xs-12 col-sm-4"
        :class="fluidClass"
        :label-text="t('landing.where')"
        :model-value="readableRegion"
        @click="openMenu('location')"
      >
        <q-tooltip>
          {{ readableRegion }}
        </q-tooltip>
      </TextInput>

      <TextInput
        readonly
        class="whenSelect col-xs-12 col-sm-4"
        :class="fluidClass"
        @click="openMenu('date')"
        :label-text="t('landing.when')"
        :model-value="readableDate"
      />

      <TextInput
        readonly
        class="amountSelect col-xs-12 col-sm-4"
        :class="fluidClass"
        @click="openMenu('guests')"
        :label-text="t('landing.amountGuests')"
        v-model="readableGuests"
      >
        <q-tooltip>
          {{ readableGuests }}
        </q-tooltip>
      </TextInput>

      <div v-if="showReset">
        <q-btn
          @click="resetFilters"
          outline
          color="primary-dark"
        >
          Reset filters
        </q-btn>
      </div>

      <slot />
    </div>

    <div
      class="menuContainer"
      v-if="showDesktopMenu"
    >
      <q-menu
        v-model="menuOpen"
        anchor="bottom middle"
        self="top middle"
        class="guestsMenu"
      >
        <div class="whenMenu">
          <q-icon
            name="icon-plus"
            class="closeIcon"
            size="sm"
            @click="menuOpen = false"
          />
          <div v-if="menuType === 'location'">
            <div class="row">
              <!-- <TextInput outlined class="col-7" v-model="searchRegion">
              <template #prepend>
                <q-icon size="14px" name="icon-search" />
              </template>
            </TextInput> -->

              <!-- <div class="col-12 q-my-sm">Filters</div> -->
            </div>
            <client-only>
              <GeoChartComponent
                @update-region="store.updateRegionData"
                :model-value="selectedLocation"
              />
            </client-only>
          </div>

          <div v-if="menuType === 'date'">
            <!-- On hold -->
            <!-- <Tabs :options="desktopDateOptions" v-model="selectedDateType" /> -->
            <q-tab-panels
              v-model="selectedDateType"
              animated
            >
              <q-tab-panel name="exact">
                <VueDatePicker
                  v-model="date"
                  class="q-pa-lg"
                  inline
                  auto-apply
                  :month-change-on-scroll="false"
                  range
                  multi-calendars
                  :enable-time-picker="false"
                  cancel-text="Annuleren"
                  select-text="Selecteren"
                  locale="nl"
                  hide-input-icon
                  :min-date="new Date()"
                />
                <!-- ON hold -->
                <!-- <ChipFilters class="q-mt-md" :options="dateSelectTypes" v-model="selectedExactData" /> -->
              </q-tab-panel>

              <q-tab-panel
                name="flexibel"
                class="q-mt-lg"
              >
                <b>Duur van verblijf</b>
                <q-option-group
                  inline
                  v-model="flexibelData.timespan"
                  :options="flexibelOptions.timespan"
                />
                <div class="q-mt-lg">
                  <b> Voorkeur voor maand </b>
                  <span class="textLowImportance q-ml-sm"> Optioneel </span>
                  <div class="row monthContainer q-col-gutter-sm">
                    <div
                      class="col-2"
                      v-for="month in flexibelOptions.months"
                      :key="month"
                      @click="flexibelData.months.push(month)"
                    >
                      <div
                        v-text="month"
                        class="month"
                        :class="{ selected: flexibelData.months.includes(month) }"
                      />
                    </div>
                  </div>
                </div>
              </q-tab-panel>
            </q-tab-panels>
          </div>

          <div v-if="menuType === 'guests'">
            <AmountGuestsFilter />
          </div>

          <div
            v-if="isSearching"
            class="buttons q-gutter-md q-mt-lg"
          >
            <q-btn
              color="primary"
              @click="onSearchClick"
            >
              Zoeken
            </q-btn>
            <q-btn> Terug </q-btn>
          </div>

          <div
            v-else
            class="q-mt-md"
          >
            <q-btn
              color="primary"
              @click="nextMenuType"
              v-if="menuType !== allMenuTypes[2]"
            >
              Volgende
            </q-btn>
            <q-btn
              color="primary"
              @click="menuOpen = false"
              v-else
            >
              Sluiten
            </q-btn>
          </div>
        </div>
      </q-menu>
    </div>

    <!-- Mobile view -->
    <DialogComponent
      v-model="showMobileMenu"
      @close="menuOpen = false"
    >
      <div class="logoContainer flex justify-center q-pb-md">
        <img
          class="logo"
          src="../assets/ecco-logo.svg"
        />
      </div>
      <q-card-section class="q-px-none">
        <Tabs
          class="mobileTabs"
          :options="mobileTabOptions"
          v-model="menuType"
        />
        <!-- <div class="tabContainer">
        <div class="tab" v-for="tab in mobileTabOptions" :key="tab.value" v-text="tab.label" @click="menuType = tab.value" :class="{ active: menuType === tab.value }" />
      </div> -->
      </q-card-section>

      <q-card-section class="q-pt-none q-px-none">
        <div>
          <q-tab-panels
            v-model="menuType"
            animated
            class="q-mb-xl q-px-none"
          >
            <q-tab-panel name="location">
              <div class="heading3">Waar wil je heen</div>

              <div class="chipContainer">
                <q-chip
                  v-for="chip in regions"
                  :key="chip"
                  color="secondary"
                  outline
                  text-color="white"
                  icon="icon-star"
                >
                  {{ chip }}
                </q-chip>
              </div>

              <client-only>
                <GeoChartComponent
                  class="q-mb-xl"
                  compact
                  @update-region="store.updateRegionData"
                  :model-value="selectedLocation"
                />
              </client-only>
            </q-tab-panel>

            <q-tab-panel name="date">
              <VueDatePicker
                class="mobileDatePicker"
                v-model="date"
                auto-apply
                inline
                range
                :enable-time-picker="false"
                locale="nl"
                :class="{ hasSelected: date.length === 2 }"
                cancel-text="Annuleren"
                select-text="Selecteren"
                model-auto
              />
            </q-tab-panel>

            <q-tab-panel
              name="guests"
              class="row flex justify-between"
            >
              <AmountGuestsFilter />
            </q-tab-panel>
          </q-tab-panels>
        </div>
      </q-card-section>

      <q-card-actions class="actions">
        <q-btn
          flat
          @click="onSearchClick"
        >
          Naar overzicht
        </q-btn>
        <q-btn
          color="primary"
          @click="nextMenuType"
          v-if="menuType !== allMenuTypes[2]"
        >
          Volgende
        </q-btn>
        <q-btn
          v-else
          color="primary"
          @click="onSearchClick"
        >
          Zoeken
        </q-btn>
      </q-card-actions>
    </DialogComponent>
  </div>
</template>

<script setup lang="ts">
import { useI18n } from 'vue-i18n';
import VueDatePicker from '@vuepic/vue-datepicker';
import TextInput from './TextInput.vue';
import AmountGuestsFilter from './AmountGuestsFilter.vue';
import DialogComponent from './DialogComponent.vue';
import GeoChartComponent from './GeoChartComponent.vue';
import useStore from '~/store/store';
import useAuthStore from '~/store/auth';

const isMobile = getIsMobile();

const props = defineProps({
  isSearching: {
    type: Boolean,
    default: false,
  },

  fluid: {
    type: Boolean,
    default: false,
  },

  showReset: {
    type: Boolean,
    default: true,
  },
});

const emit = defineEmits(['open-modal', 'search-clicked', 'reset-clicked']);
const store = useStore();
const authStore = useAuthStore();
const isAdmin = computed(() => authStore.user?.isAdmin);
const fluidClass = props.fluid ? 'col-md-4' : 'col-md-3 col-lg-2';

const { t } = useI18n();
const guestData = computed(() => store.guestData);
const readableGuests = computed(() => store.formattedGuests);

const date = toRef(store.dateRangeData);

watch(store.dateRangeData, (val) => {
  if (!val.length) date.value = [];
});
watch(date, (value) => {
  store.updateDateRangeData(value);
});

const selectedLocation = computed(() => store.regionData);
const readableRegion = computed(() => {
  if (!selectedLocation.value?.regionIds?.length && !selectedLocation.value?.environmentIds?.length) return "Regio's, streken en meren";
  return locationMapping(selectedLocation.value);
});

// ONHOLD
// const dateSelectTypes = [
//   { label: 'Exacte data', value: 0 },
//   { label: '± 1 dag', value: 1 },
//   { label: '± 2 dag', value: 2 },
//   { label: '± 3 dag', value: 3 },
//   { label: '± 4 dag', value: 4 },
// ];

// Somehow without a computed it screws with reactivity
const searchPayload = computed(() => ({
  date: date.value.length ? date.value?.map((item) => toUtcAndStripTimeFormat(item)) : [],
  location: {
    regionIds: selectedLocation.value.regionIds,
    environmentIds: selectedLocation.value.environmentIds,
  },
  guests: guestData.value,
}));

function onSearchClick() {
  menuOpen.value = false;
  emit('search-clicked', unref({ ...searchPayload.value }));
}

const readableDate = computed(() => {
  if (date.value?.length !== 2) return 'Periode van - tot';
  return date.value.map((item) => shortDateFormat(item).toString()).join(', ');
});

const menuOpen = ref(false);
const showMobileMenu = computed(() => menuOpen.value && isMobile.value);
const showDesktopMenu = computed(() => !isMobile.value);

watch(menuOpen, (value) => {
  emit('open-modal', value);
});

const allMenuTypes = ['location', 'date', 'guests'];
const menuType = ref('location');

function openMenu(type: string) {
  menuOpen.value = true;
  menuType.value = type;
}

// On hold
// const desktopDateOptions = [
//   { label: 'Exact', value: 'exact' },
//   { label: 'Flexibel', value: 'flexibel' },
// ];
const selectedDateType = ref('exact');

const flexibelOptions = {
  timespan: [
    {
      label: 'Weekend',
      value: 0,
    },
    {
      label: 'Week',
      value: 1,
    },
    {
      label: '10 dagen',
      value: 2,
    },
    {
      label: '2 weken',
      value: 3,
    },
  ],
  months: ['Januari', 'Februari', 'Maart', 'April', 'Mei', 'Juni', 'Juli', 'Augustus', 'September', 'Oktober', 'November', 'December'],
};

const flexibelData = reactive({
  timespan: 0,
  months: ['Januari'],
});

// on hold
// const selectedExactData = ref(['Exacte data']);

const mobileTabOptions = [
  { label: t('landing.filters.mobileModal.location'), value: allMenuTypes[0] },
  { label: t('landing.filters.mobileModal.date'), value: allMenuTypes[1] },
  { label: t('landing.filters.mobileModal.guests'), value: allMenuTypes[2] },
];

function nextMenuType() {
  const currentIdx = allMenuTypes.findIndex((value) => value === menuType.value);
  const newValue = allMenuTypes[currentIdx + 1];
  if (newValue) {
    menuType.value = newValue;
  }
}

function resetFilters() {
  store.resetAllFilters();
  emit('reset-clicked');
}
</script>

<style lang="scss" scoped>
@import '~/styles/variables.sass';

.filters {
  width: 100%;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  // gap: 20px;

  .TextInput {
    // margin: 0 20px;
    //margin: 20px;
    //  width: 250px;
  }
}

.menu {
  z-index: 9999;
}

.menuContainer {
  width: 100%;
}

.AmountGuestsFilter {
  max-width: 590px;
  padding: 24px;
}

.whenMenu {
  max-width: 800px;
  padding: 24px;
}

.closeIcon {
  position: absolute;
  top: 15px;
  right: 15px;
  transform: rotate(45deg);
  z-index: 9999;
  cursor: pointer;
  color: #a3a8ad;
}

.monthContainer {
  .month {
    padding: 26px 0;
    border: 1px solid $sea-blue-300;
    border-radius: 8px;
    text-align: center;

    &.selected {
      border: 2px solid $sea-blue-400;
      background: $backgrounds-100;
      font-weight: bold;
    }
  }
}

@media (max-width: $screen-xs) {
  .mobileTabs {
    margin: auto;
  }

  .filters {
    justify-content: space-between;
  }

  .chipContainer {
    display: flex;
    overflow: auto;
  }

  :deep(.mobileDatePicker) {
    // width: fit-content;
    // margin: auto;
  }

  .actions {
    position: fixed;
    bottom: 0;
    // background: $backgrounds-50;
    border-top: 1px solid $accent;
    display: flex;
    width: 100%;
    height: 80px;
    left: 0;
    justify-content: space-between;
  }
}
</style>
