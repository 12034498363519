<template>
  <div class="row container">
    <div :class="[compact ? 'col-12' : 'col-7']">
      <div class="q-mb-md">Regio's</div>
      <q-chip
        v-for="(button, idx) in displayableRegionIdFilters"
        :key="idx"
        clickable
        outline
        @click="handlePillButtonClick(button)"
        :class="{ selected: selectedRegionsNames.includes(button.NAME_1), hovered: hoveredRegion?.ID_1 === button.ID_1 }"
        @mouseover="hoveredRegion = button"
        @mouseout="hoveredRegion = null"
      >
        {{ button.NAME_1 }}
      </q-chip>

      <div
        v-if="!showAllRegionIdFilters"
        class="textLink pointer q-mt-sm q-mb-lg"
        @click="showAllRegionIdFilters = true"
      >
        Toon alle regio's
      </div>

      <div class="q-my-md">Meren en streken</div>

      <q-chip
        v-for="filter in displayableEnvironmentFilters"
        clickable
        outline
        :class="{ selected: environmentIds.includes(filter.value) }"
        @click="handleEnvironmentButtonClick(filter.value)"
      >
        {{ filter.label }}
      </q-chip>

      <div
        v-if="!showAllEnvironmentFilters"
        class="textLink pointer q-mt-xs"
        @click="showAllEnvironmentFilters = true"
      >
        Toon alle meren
      </div>
    </div>
    <div
      v-if="!compact"
      id="geoChart"
      class="col-5"
    />
  </div>
</template>

<script setup lang="ts">
import * as d3 from 'd3';
import useStore from '~/store/store';

const props = defineProps({
  modelValue: {
    type: Object,
    required: false,
  },

  compact: {
    type: Boolean,
    default: false,
  },
});

const emit = defineEmits(['update-region']);

const store = useStore();
const geojson = computed<{ features: GEO_ENTRY[] }>(() => store.topoJsonRegions);

const { selectedRegions, selectedRegionsNames, hoveredRegion, displayableRegionIdFilters, showAllRegionIdFilters } = regionIdsHandler();
const { displayableEnvironmentFilters, showAllEnvironmentFilters, environmentIds, handleEnvironmentButtonClick } = environmentHandlers();
locationDataHandler();
const { draw, handlePillButtonClick } = chartHandlers();

onMounted(() => {
  if (!props.compact) draw();
});

function regionIdsHandler() {
  const pillButtons = computed(() =>
    geojson.value?.features?.filter((item: GEO_ENTRY) => item.properties.show).map((item: GEO_ENTRY) => item.properties)
  );
  const showAllRegionIdFilters = ref(false);
  const displayableRegionIdFilters = computed(() =>
    showAllRegionIdFilters.value
      ? pillButtons.value
      : [
          ...pillButtons.value.filter((item: GEO_ENTRY) => item.hasPrio),
          ...pillButtons.value.filter((item: GEO_ENTRY) => props.modelValue?.regionIds.includes(item.ID_1) && !item.hasPrio),
        ]
  );
  const selectedRegions = ref<GEO_ENTRY_PROPERTIES[]>([]);
  const selectedRegionsNames = computed(() => selectedRegions.value.map((item: GEO_ENTRY_PROPERTIES) => item.NAME_1));
  const hoveredRegion = ref<GEO_ENTRY_PROPERTIES | null>();

  if (props.modelValue?.regionIds?.length) {
    selectedRegions.value = pillButtons.value.filter((item) => props.modelValue?.regionIds.includes(item.ID_1));
  }

  return {
    selectedRegions,
    selectedRegionsNames,
    hoveredRegion,
    showAllRegionIdFilters,
    displayableRegionIdFilters,
  };
}

function locationDataHandler() {
  const selectedLocationProperties = computed(() => ({
    regionIds: selectedRegions.value?.map((item) => item.ID_1),
    environmentIds: environmentIds.value?.map((item) => item),
  }));

  watch(selectedLocationProperties, (val) => {
    emit('update-region', val);
  });
}

function environmentHandlers() {
  const environmentIds = ref<number[]>([]);
  environmentIds.value = props.modelValue?.environmentIds?.length ? props.modelValue?.environmentIds : [];
  const environmentFilters = computed(() => store.environmentFilters);
  const showAllEnvironmentFilters = ref(false);
  const displayableEnvironmentFilters = computed(() =>
    showAllEnvironmentFilters.value ? environmentFilters.value : environmentFilters.value.slice(0, 5)
  );

  function handleEnvironmentButtonClick(id: number) {
    if (environmentIds.value.includes(id)) {
      environmentIds.value.splice(environmentIds.value.indexOf(id), 1);
    } else {
      environmentIds.value.push(id);
    }
  }

  return { displayableEnvironmentFilters, showAllEnvironmentFilters, environmentIds, handleEnvironmentButtonClick };
}

function chartHandlers() {
  const DEFAULT_COLOR = '#ccc';
  const HOVER_COLOR = '#8b8c8c';
  const ACTIVE_COLOR = '#0E1216';

  watch(hoveredRegion, (newVal: GEO_ENTRY_PROPERTIES, oldVal: GEO_ENTRY_PROPERTIES) => {
    if (oldVal?.ID_1) {
      d3.select('#geoChart')
        .select(`#province-${oldVal.ID_1}`)
        .style('fill', (oldVal: GEO_ENTRY) => setColor(oldVal));
    }

    if (newVal && !newVal.clicked) {
      d3.select('#geoChart').select(`#province-${newVal.ID_1}`).style('fill', HOVER_COLOR);
    }
  });

  function handlePillButtonClick(d: GEO_ENTRY_PROPERTIES) {
    if (!d.show) return;
    if (selectedRegionsNames.value.includes(d.NAME_1)) {
      selectedRegions.value.splice(selectedRegionsNames.value.indexOf(d.NAME_1), 1);
    } else {
      selectedRegions.value.push(d);
    }
    d.clicked = !d.clicked;

    d3.select('#geoChart')
      .select(`#province-${d.ID_1}`)
      .style('fill', (d: GEO_ENTRY) => setColor(d));
  }

  function setColor(item: GEO_ENTRY) {
    if (item.properties.clicked) {
      return ACTIVE_COLOR;
    }
    return DEFAULT_COLOR;
  }

  function draw() {
    const windowWidth = window.innerWidth;

    const width = windowWidth > 300 ? 300 : windowWidth;
    const height = width;

    const projection = d3
      .geoMercator()
      .scale(width * 3)
      .center([12.5674, 41.8719]) // strange magic
      .translate([width / 2, height / 2])
      .precision(0.1);

    const path = d3.geoPath().projection(projection);
    const map = d3
      .select('#geoChart')
      .append('svg')
      .attr('id', 'geochart')
      .attr('preserveAspectRatio', 'xMinYMin meet')
      .attr('viewBox', '30 20 210 250');

    try {
      (async () => {
        map
          .selectAll('path')
          .data(geojson.value.features)
          .enter()
          .append('path')
          .attr('id', (d) => `province-${d.properties.ID_1}`)
          .attr('d', path as any)
          .style('stroke', 'white')
          .style('fill', (d) => {
            // Regions kunnen pre selected vanuit de URL komen
            const isPreSelected = selectedRegions.value.findIndex((selected) => selected.ID_1 === d.properties.ID_1) > -1;

            if (isPreSelected) {
              d.properties.clicked = true;
              return ACTIVE_COLOR;
            } else {
              d.properties.clicked = false;
              return setColor(d);
            }
          })
          .on('click', function (_, d) {
            handlePillButtonClick(d.properties);
          })
          .on('mouseover', function (_, d) {
            if (!d.properties.show) return;
            hoveredRegion.value = d.properties;
          })
          .on('mouseout', function () {
            hoveredRegion.value = null;
          });
      })();
    } catch (e) {
      console.error(e);
    }
  }

  return {
    draw,
    setColor,
    handlePillButtonClick,
  };
}
</script>

<style lang="scss" scoped>
@import '../styles/variables.sass';

#geoChart {
  border-left: 1px solid $sea-blue-300;
}

.container {
  max-width: 800px;
}

.q-chip {
  &.selected {
    font-weight: 600;
    // background: $backgrounds-100 !important;
  }

  &.hovered {
    // background: $backgrounds-100 !important;
  }
}
</style>
