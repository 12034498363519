<template>
  <div class="row">
    <div class="col-12">
      <div
        class="heading2 q-mb-lg"
        v-text="content.titel"
        v-if="content.titel"
      />
      <div>
        <iframe
          :width="frameWidth"
          height="580px"
          :src="youtubeUrl"
          title="YouTube video player"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          allowfullscreen
        />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
const props = defineProps({
  content: {
    type: Object as PropType<VideoContentBlock>,
    required: true,
  },
});

const frameWidth = computed(() => (props.content.weergave === 'small' ? '50%' : '100%'));
const youtubeUrl = computed(() => props.content.youtubeVimeoLink.replace('/watch?v=', '/embed/'));
</script>

<style lang="scss" scoped></style>
