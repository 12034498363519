<template>
  <div
    class="row"
    :class="reverseClass"
  >
    <div class="col-xs-12 col-sm-6 tekst">
      <div class="heading2">
        {{ content.titel }}
      </div>

      <div
        v-html="content.tekst"
        class="q-mt-lg light raw"
      />
      <ContentblokkenButton
        :name="content.knop.name"
        :link="content.knop.link"
        :target="content.knop.target"
      />
    </div>
    <div class="col-xs-12 col-sm-6 imageContainer">
      <NuxtImg
        class="Image full-width"
        :src="content.afbeelding.id.toString()"
        provider="baseProvider"
        width="100%"
        height="auto"
        loading="lazy"
        sizes="lg:600px md:400px sm:350px xs:400px"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
const props = defineProps({
  content: {
    type: Object as PropType<TekstEnAfbeeldingContentBlock>,
    required: true,
  },
});

const reverseClass = props.content.uitlijningTekst === 'false' ? 'reverse' : 'non-reverse';
</script>

<style lang="scss" scoped>
@import '~/styles/variables.sass';

.Image {
  border-radius: 8px;
  float: right;
}

.reverse {
  .tekst {
    padding-left: 24px;
  }
}

.non-reverse {
  .tekst {
    padding-right: 24px;
  }
}

.imageContainer {
  border-radius: 8px;
  background-repeat: no-repeat;
}

@media (max-width: $screen-xs) {
  .Image {
    margin-top: 10px;
  }

  .heading2 {
    font-size: 24px;
  }

  .reverse {
    .tekst {
      padding-left: 0;
    }
  }
}
</style>
