<template>
  <div>
    <div
      class="heading2 q-mb-lg heading"
      v-text="content.titel"
      v-if="content.titel"
    />

    <a :href="link">
      <NuxtImg
        class="Image"
        format="webp"
        v-if="content.afbeelding"
        provider="baseProvider"
        width="100%"
        height="auto"
        loading="lazy"
        :class="{ pointer: content.link, small: content.weergave === 'small' }"
        :src="content.afbeelding.toString()"
        sizes="lg:1200px md:800px sm:700px xs:500px"
        :alt="content.afbeeldingAltTekst"
      />
    </a>
  </div>
</template>

<script setup lang="ts">
const props = defineProps({
  content: {
    type: Object as PropType<AfbeeldingContentBlockType>,
    required: true,
  },
});

const link = computed(() => (props.content.link.length > 1 ? props.content.link : undefined));
</script>

<style lang="scss" scoped>
@import '~/styles/variables.sass';

.Image {
  width: 100%;
  border-radius: 8px;

  &.small {
    max-width: 50%;
  }
}

@media (max-width: $screen-xs) {
  .Image {
    width: 100%;
    max-width: 100%;

    &.small {
      max-width: 100%;
    }
  }
}
</style>
