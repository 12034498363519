export default {
  breadcrumbs: {
    home: 'Home',
    myAccount: 'My account',
    editAccount: 'Edit account',
    travelApplications: 'Incoming travel applications',
    travelApplication: 'Incoming travel application',
    upcomingTrips: 'Upcoming Trips',
  },

  favorites: {
    title: 'Favorites',
    description: 'View your saved favorites',
  },

  inquiryForCustomer: {
    title: 'Create travel advice',
    description: 'Create travel advice for new or existing customers',
  },

  bookingForCustomer: {
    title: 'Create booking',
    description: 'Create a booking for new or existing customers',
  },

  incomingTrips: {
    title: 'Issued travel advice',
    description: 'Search for customers with issued travel advice',
  },

  bookings: {
    title: 'Options and bookings',
    description: 'View your bookings and options',
  },

  owners: {
    title: 'Booking calendar. (Formerly portale online)',
    description: 'Update your calendar here!',
  },

  changePassword: {
    title: 'Change password',
    description: 'Change your password',
  },

  logOut: {
    title: 'Log out',
    description: 'Log out of your account',
  },

  editAccount: {
    title: 'Manage account',
    description: 'Update your details',
    male: 'Male',
    female: 'Female',
    other: 'Other',
    firstName: 'First name',
    between: 'Middle name',
    lastName: 'Last name',
    birthDate: 'Birth date',
    emailAddress: 'Email address',
    zipCode: 'Zip code',
    number: 'House number',
    addition: 'Addition',
    place: 'Place',
    street: 'Street',
    country: 'Country',
    save: 'Save details',
    telephone: 'Telephone number',
    password: 'Password',
    repeatPassword: 'Repeat password',
  },

  travelApplications: {
    addTravelApplication: 'Add travel application',
    open: 'Open',
  },
};
