export const LANDING_PAGE_ID = 2075;
export const QUICKLINKS_PAGE_ID = 2216;
export const ABOUT_PAGE_ID = 2117;
export const FOOTER_LINKS_PAGE_ID = 2217;
export const DESTINATIONS_PAGE_ID = 2306;

// filters
export const ACCOMODATION_TYPES = 'accommodationTypes';
export const ACCOMODATION_SUB_FILTERS = 'accommodationSubFilters';
export const HOLIDAY_TYPES = 'holidayTypes';
export const FACILITIES = 'facilities';
export const FEATURES = 'features';
export const QUALITY_LABELS = 'qualityLabels';
export const LABELS = 'labels';
export const DISCOUNTS = 'discounts';

export const GENDER_IDS: Record<string, number> = {
  none: 0,
  man: 2,
  vrouw: 1,
  anders: 3,
};

export const GENDER_IDS_NUMBERS: Record<number, string> = {
  0: '-',
  2: 'man',
  1: 'vrouw',
  3: 'anders',
};

export const BOOKING_STEPS = {
  INITIALIZE: 0,
  ADDING_GUESTS: 10,
  ADDING_PRODUCTS: 20,
  SUMMARY: 30,
  FINISHED: 40,
};

export const USER_LEVELS = {
  None: 0,
  Admin: 1,
  User: 2,
  Reseller: 6,
  Owner: 7,
  Booking: 8,
};

export const UNIT_FLOOR_LEVELS: Record<number, string> = {
  0: 'Begane grond',
  1: '1e verdieping',
  2: '2e verdieping',
  3: '3e verdieping',
  4: 'BG of 1e verdieping',
};

export const DEFAULT_FLOOR_ITEM = { label: 'Alle verdiepingen', value: -1 };

export const SEARCH_PREFERENCE_OPTIONS = [
  { label: 'Populariteit', value: 2 },
  // { label: 'Minst populair', value: 3 },
  // { label: 'Met label of actie', value: 4 },
  { label: 'Naam A-Z', value: 0 },
  { label: 'Naam Z-A', value: 1 },
];

export const SEARCH_PREFERENCE_DEFAULT = 2;
