<template>
  <div>
    <q-chip
      v-for="item in options"
      :key="item.value"
      outline
      @click="handleClick(item)"
      class="chip"
      :class="{ selected: internalValues?.includes(item.value) }"
      clickable
    >
      {{ item.label }}
    </q-chip>
  </div>
</template>

<script setup lang="ts">
const props = defineProps({
  options: {
    type: Array<{ label: string; value: string }>,
    required: true,
  },
  singleSelect: {
    type: Boolean,
    default: false,
  },
  modelValue: {
    type: Array,
    default: () => [],
  },
});

const emit = defineEmits(['update:modelValue']);

const internalValue = ref(props.modelValue || []);
const internalValues = computed(() => internalValue.value?.map((item) => item?.value));

function handleClick(item: { label: string; value: string }) {
  if (internalValues.value?.includes(item.value)) {
    internalValue.value.splice(internalValues.value?.indexOf(item.value), 1);
  } else {
    if (props.singleSelect) {
      internalValue.value.splice(0);
    }
    internalValue.value.push(item);
  }
  emit('update:modelValue', internalValue.value);
}
</script>
