<template>
  <div class="PriceRangeSlider">
    <div class="priceRangeContainer">
      <q-range
        v-model="range"
        class="rangeSlider"
        track-size="2px"
        thumb-size="15px"
        :min="0"
        :max="MAX_VALUE"
        @update:model-value="emit('update:model-value', $event)"
      />
    </div>

    <div class="inputs">
      <TextInput
        v-model="modelValue.min"
        label-text="€ Min"
        type="number"
        @update:model-value="emit('update:model-value', { min: parseInt($event), max: parseInt(modelValue.max) })"
      />
      <div class="divider" />
      <TextInput
        v-model="modelValue.max"
        label-text="€ Max"
        type="number"
        @update:model-value="emit('update:model-value', { min: parseInt(modelValue.min), max: parseInt($event) })"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue';
import TextInput from './TextInput.vue';
const MAX_VALUE = 5000;

const props = defineProps({
  modelValue: {
    type: Object,
    default: () => ({ min: 0, max: 0 }),
  },

  minPrice: {
    type: Number,
    default: 0,
  },

  maxPrice: {
    type: Number,
    default: MAX_VALUE,
  },
});

const emit = defineEmits(['update:model-value']);
props.modelValue.min = props.minPrice;
props.modelValue.max = props.maxPrice || MAX_VALUE;

const range = computed({
  get() {
    return { min: props.modelValue.min, max: props.modelValue.max };
  },
  set(newValue) {
    // console.log(newValue);
    emit('update:model-value', newValue);
  },
});

watch(
  () => props.modelValue,
  (newValue) => {
    console.log(newValue);
    // props.modelValue.min = newValue;
  },
  { deep: true }
);
</script>

<style lang="scss" scoped>
@import '../styles/variables.sass';

.PriceRangeSlider {
  display: flex;
  flex-direction: column;
}

.pricePointsContainer {
  margin-bottom: -14px;
  padding: 0 10px;
}

:deep(.rangeSlider) {
  .q-slider__track {
    background: $sea-blue-200;
  }
  .q-slider__selection {
    background: $calm-blue-300;
  }

  .q-slider__thumb {
    width: 20px !important;
    height: 20px !important;
  }

  .q-slider__thumb-shape {
    color: $calm-blue-400;
  }
}

.inputs {
  display: flex;
  justify-content: space-between;
  align-items: center;

  .TextInput {
    width: 100%;
    cursor: initial;
  }

  .divider {
    width: 20px;
    border-bottom: 2px solid $sea-blue-400;
    padding: 10px;
    margin: 20px;
  }
}
</style>
