<template>
  <div class="ShoppingCart">
    <BoekenShoppingCartItem
      :can-delete="canDelete"
      class="ShoppingCartItem"
      v-for="(item, idx) in shoppingCart"
      :key="idx"
      :accommodation="item"
      @delete="deleteFromShoppingCart(idx)"
    />

    <BoekenShoppingCartPricing class="q-mb-lg" />

    <q-btn
      v-if="hasButton"
      color="primary"
      class="bookButton"
      :to="bookingRouteName"
    >
      {{ buttonText }}
    </q-btn>
  </div>
</template>

<script setup lang="ts">
import useShoppingCartStore from '~/store/shoppingCart';
import useBookingStore from '~/store/booking';
import useAuthStore from '~/store/auth';

defineProps({
  canDelete: {
    type: Boolean,
    default: false,
  },

  hasButton: {
    type: Boolean,
    default: false,
  },
});

const emit = defineEmits(['empty', 'has-items']);
const authStore = useAuthStore();
const shoppingCartStore = useShoppingCartStore();
const bookingStore = useBookingStore();
const shoppingCart = computed(() => shoppingCartStore.currentShoppingCart);
const hasActiveBooking = computed(() => bookingStore.bookingId);
const bookingRouteName = computed(() => (authStore.user.isAdmin ? routeNames.bookingForCustomer : routeNames.booking));
const buttonText = computed(() => {
  if (authStore.user.isAdmin) return 'Boek voor klant';
  return hasActiveBooking.value ? 'Hervat boeking' : 'Boeken';
});

watch(
  shoppingCartStore.currentShoppingCart,
  (value) => {
    if (!value.length) emit('empty');
    else emit('has-items');
  },
  { immediate: true }
);
</script>

<style lang="scss" scoped>
@import '../../styles/variables.sass';

.ShoppingCartItem {
  border-bottom: 2px solid $backgrounds-150;
  padding-bottom: 24px;
}

.q-btn {
  width: 100%;
  display: block;
}

@media (max-width: $screen-xs) {
  .q-btn {
    width: 100%;
    display: block;
  }
}
</style>
