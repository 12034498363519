import dayjs from 'dayjs';
import 'dayjs/locale/nl';

dayjs.locale('nl');
// todo: even betere namen zeg
export const standardDateFormat = (date: Date) => dayjs(date).format('DD-MM-YYYY');
export const standardDateTimeFormat = (date: Date) => dayjs(date).format('DD-MM-YYYY HH:mm');
export const shortDateFormat = (date: Date) => dayjs(date).format('D MMM');
export const monthYearFormat = (date: Date) => dayjs(date).format('MMMM YYYY');
export const dayMonthFormat = (date: Date) => dayjs(date).format('DD MMMM');
export const dayMonthYearFormat = (date: Date) => dayjs(date).format('DD MMMM YYYY');

export function toUtcAndStripTimeFormat(string: string | Date) {
  return dayjs(string).format('YYYY-MM-DD') + 'T00:00:00.000Z';
}
