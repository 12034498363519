<template>
  <DialogComponent v-model="showShoppingCartAdded">
    <div class="ShoppingCartModal">
      <div class="heading2">Accommodatie toegevoegd!</div>

      <div class="heading4 q-mb-lg">Je vakantiegevoel kan bijna beginnen</div>
      <BoekenShoppingCartItem
        v-for="(item, idx) in shoppingCartItems"
        fixed-width
        can-delete
        compact
        :accommodation="item"
        @delete="deleteFromShoppingCart(idx)"
      />

      <div class="tussenstopCard q-pa-lg row items-center">
        <div class="col-12">
          <div class="heading4">Ik zoek voor mijn vakantie nog een mooie locatie</div>
          <div>Je bespaart op boekingskosten als je meerdere locaties tegelijk boekt!</div>
        </div>
      </div>

      <div class="q-mt-lg q-gutter-x-md">
        <q-btn
          color="primary"
          :to="bookingRouteName"
        >
          Boeken <span v-if="isAdmin">&nbsp;voor klant </span>
        </q-btn>

        <q-btn
          color="primary-dark"
          outline
          @click="showShoppingCartAdded = false"
        >
          {{ cancelButtonText }}
        </q-btn>
      </div>
    </div>
  </DialogComponent>
</template>

<script setup lang="ts">
import useShoppingCartStore from '~/store/shoppingCart';
import useAuthStore from '~/store/auth';

const shoppingCartStore = useShoppingCartStore();
const authStore = useAuthStore();
const showShoppingCartAdded = ref(false);
const isAdmin = computed(() => authStore.user.isAdmin);
const shoppingCartItems = computed(() => shoppingCartStore.currentShoppingCart);
const shoppingCartSize = computed(() => shoppingCartItems.value.length);
const route = useRoute();
const bookingRouteName = computed(() => (authStore.user.isAdmin ? routeNames.bookingForCustomer : routeNames.booking));

watch(shoppingCartSize, (newVal, oldVal) => {
  // open on add, close on empty
  if (newVal > oldVal) {
    showShoppingCartAdded.value = true;
  } else {
    if (!newVal) {
      showShoppingCartAdded.value = false;
    }
  }
});

const cancelButtonText = computed(() => (route.path.includes(routeNames.upcomingTrips) ? 'Terug naar reisadvies' : 'Verder zoeken'));
</script>

<style lang="scss" scoped>
.ShoppingCartModal {
  max-width: 800px;
}

.tussenstopCard {
  background: $backgrounds-50;
  border-radius: 16px;
}
</style>
