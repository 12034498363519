<template>
  <div
    class="ShoppingCartItem row q-my-lg"
    v-if="accommodation"
  >
    <div
      class="thumbnail"
      :class="[compact ? 'col-xs-2' : 'col-xs-4']"
    >
      <router-link :to="`${routeNames.aanbod}/${accommodation?.slug}`">
        <Image :src="accommodation.thumbnail" />
      </router-link>
    </div>

    <div
      class="accommodationName"
      :class="[compact ? 'col-xs-9' : 'col-xs-7']"
    >
      <div
        class="headingSmall header"
        v-text="accommodation.name"
      />
      <div
        v-text="accommodation.region"
        class="textLowImportance region"
      />
    </div>

    <div
      v-if="canDelete"
      class="col-xs-1 remove"
    >
      <q-btn
        class="iconButton"
        @click="emit('delete')"
      >
        <q-tooltip class="shoppingCartTooltip"> Verwijderen </q-tooltip>
        <q-icon
          name="icon-close"
          size="24px"
        />
      </q-btn>
    </div>

    <div class="col-xs-12 q-pa-md q-mt-md bookingInfo">
      <strong v-text="unit.name" />
      <div class="row justify-between q-mt-sm">
        <div v-text="getTripDate(unit.fromDate, unit.toDate)" />

        <div>{{ unit.numberOfGuests }} gasten</div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
const props = defineProps({
  // compact en voor de samenvatting wordt 'variant'
  // miss gewoon die op een ander component maken, wordt anders teveel if's
  compact: {
    type: Boolean,
    default: false,
  },

  accommodation: {
    type: Object as PropType<ShoppingCartItem>,
    required: true,
  },

  canDelete: {
    type: Boolean,
    default: false,
  },

  fixedWidth: {
    type: Boolean,
    default: false,
  },
});

const emit = defineEmits(['delete']);

function getTripDate(from: Date, to: Date) {
  return `${dayMonthFormat(from)} - ${dayMonthYearFormat(to)}`;
}

const unit = computed(() => props.accommodation.units[0]);
</script>

<style lang="scss" scoped>
@import '../../styles/variables.sass';

.bookingInfo {
  background: $backgrounds-50;
  border-radius: 16px;
}

.fixedWidth {
  max-width: 150px;
}

.accommodationName {
  padding-left: 20px;

  .header {
    color: $calm-blue-500;
  }

  .region {
    color: $calm-blue-400;
  }
}
</style>
