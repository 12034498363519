import { createI18n } from 'vue-i18n';
import translations from '../i18n/translations';

export const i18n = createI18n({
  legacy: false,
  globalInjection: true,
  locale: 'nl',
  defaultLocale: 'nl',
  fallbackLocale: 'nl',
  messages: translations,
  localeDetection: false,
});

export default defineNuxtPlugin(({ vueApp }) => {
  vueApp.use(i18n);
});
