export default {
  notFound: 'notFound',
  landing: '/accommodaties',
  aanbod: '/accommodaties',
  login: 'login',
  register: 'register',
  winkelwagen: '/winkelwagen',
  accomodationDetail: '/accommodaties',
  contentblok: 'contentblok',
  account: '/account',
  boekingGedaan: '/boeken/gedaan',
  editAccount: '/account-bewerken',
  manageTeam: '/team-beheren',
  travelApplications: '/suggesties',
  travelApplicationDetail: 'travelApplicationDetail',
  verify: 'verify',
  forgotPassword: 'forgotPassword',
  resetPassword: 'resetPassword',
  booking: '/boeken',
  inquiry: '/reis-aanvragen',
  inquiryForCustomer: '/reis-aanvragen-klant',
  bookingForCustomer: '/boeking-aanmaken-klant',
  viewBookingAdmin: '/boekingen/admin',
  viewOwnersAdmin: '/eigenaren/admin',
  travelAdvice: '/reis-advies-opstellen',
  travelSelected: 'travelSelected',
  contact: '/contact',
  boekingen: '/boekingen',
  upcomingTrips: '/aankomende-reizen',
  incomingTrips: '/inkomende-reisaanvragen',
  owners: '/eigenaren',
  favorites: '/favorieten',
};
