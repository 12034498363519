import * as validators from '@vuelidate/validators';
import { createI18nMessage } from '@vuelidate/validators';
import { i18n } from '~/plugins/i18n';

const withI18nMessage = createI18nMessage({ t: i18n.global.t.bind(i18n) });
export const required = withI18nMessage(validators.required);
export const minLength = withI18nMessage(validators.minLength, { withArguments: true });
export const maxLength = withI18nMessage(validators.maxLength, { withArguments: true });
export const email = withI18nMessage(validators.email);
export const sameAs = withI18nMessage(validators.sameAs, { withArguments: true });
export const textOnly = withI18nMessage(validators.alpha);
export const number = withI18nMessage(validators.numeric);
export const phoneNumber = withI18nMessage((value: string) => /^\+?[0-9]{6,}$/.test(value));
export const passwordValidation = withI18nMessage((value: string) => /(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*/()])/.test(value));
