<template>
  <div class="row">
    <div class="col-xs-12">
      <div class="heading2">
        {{ content.titel }}
      </div>

      <div class="q-gutter-lg q-mt-lg">
        <div
          v-for="(document, idx) in documents"
          :key="idx"
          class="document row justify-between items-center"
        >
          <div class="heading2 documentTitle">
            {{ document.name }}
          </div>

          <div class="buttonContainer">
            <q-btn
              color="primary"
              @click="downloadFile(document)"
            >
              <q-icon name="icon-download" />
              Download
            </q-btn>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
const props = defineProps({
  content: {
    type: Object as PropType<DocumentenContentBlock>,
    required: true,
  },
});

interface Document {
  id: number;
  name: string;
}

const MAX_DOCUMENTS = 5;
const documents = ref<Document[]>([]);
for (let i = 1; i < MAX_DOCUMENTS + 1; i++) {
  if (props.content['document' + i]) {
    documents.value.push({
      id: props.content['document' + i],
      name: props.content['document' + i + 'Naam'],
    });
  }
}

async function downloadFile(doc: Document) {
  const res = await fetchData('/api/media/' + doc.id);

  const blob = new Blob([res], { type: 'application/pdf' });
  const url = URL.createObjectURL(blob);
  const link = document.createElement('a');
  link.setAttribute('href', url);
  link.setAttribute('download', doc.name);
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
}

// const documenten = computed(() => )

// const FAQ_URL = 'https://smookitalia-api.tc8l.dev/api/page/2111/';
// const allQuestions = props.content.vragen.split(', ');
// const questions = ref<Question[]>([]);

// for (let q of allQuestions) {
//   const { data }: { data: Question[] } = await useFetch(FAQ_URL + q);
//   questions.value.push(data.value);
// }
</script>

<style lang="scss" scoped>
@import '~/styles/variables.sass';

.document {
  border-radius: 16px;
  background: $calm-blue-400;
  padding: 40px;
  color: white;
}

@media (max-width: $screen-xs) {
  .document {
    padding: 16px 24px 16px 24px;
    display: flex;
    flex-direction: column;
    gap: 20px;
    align-items: baseline;

    .buttonContainer {
      width: 100%;
    }

    .q-btn {
      float: right;
    }
  }
  .documentTitle {
    font-size: 20px;
  }
}
</style>
