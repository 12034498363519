import { default as _91level3_93owbQPvqXcFMeta } from "D:/GitLab-Runner/build/gV_R9zkRE/0/SmookItalia/smookitalia-nuxt-app/pages/[slug]/[level2]/[level3].vue?macro=true";
import { default as indexKfbAcadamWMeta } from "D:/GitLab-Runner/build/gV_R9zkRE/0/SmookItalia/smookitalia-nuxt-app/pages/[slug]/[level2]/index.vue?macro=true";
import { default as index0oDcl6TXBUMeta } from "D:/GitLab-Runner/build/gV_R9zkRE/0/SmookItalia/smookitalia-nuxt-app/pages/[slug]/index.vue?macro=true";
import { default as _91id_93BJj8EU8V1TMeta } from "D:/GitLab-Runner/build/gV_R9zkRE/0/SmookItalia/smookitalia-nuxt-app/pages/aankomende-reizen/[id].vue?macro=true";
import { default as index11XR6hdXSNMeta } from "D:/GitLab-Runner/build/gV_R9zkRE/0/SmookItalia/smookitalia-nuxt-app/pages/aankomende-reizen/index.vue?macro=true";
import { default as token_45verlopenwKj2LOXPmQMeta } from "D:/GitLab-Runner/build/gV_R9zkRE/0/SmookItalia/smookitalia-nuxt-app/pages/aankomende-reizen/token-verlopen.vue?macro=true";
import { default as _91id_93QEkPIbDPgtMeta } from "D:/GitLab-Runner/build/gV_R9zkRE/0/SmookItalia/smookitalia-nuxt-app/pages/accommodaties/[id].vue?macro=true";
import { default as indexfcmJCZJ0jTMeta } from "D:/GitLab-Runner/build/gV_R9zkRE/0/SmookItalia/smookitalia-nuxt-app/pages/accommodaties/index.vue?macro=true";
import { default as account_45bewerkenMaRii9PBkrMeta } from "D:/GitLab-Runner/build/gV_R9zkRE/0/SmookItalia/smookitalia-nuxt-app/pages/account-bewerken.vue?macro=true";
import { default as accountgNZdXx2SySMeta } from "D:/GitLab-Runner/build/gV_R9zkRE/0/SmookItalia/smookitalia-nuxt-app/pages/account.vue?macro=true";
import { default as gedaanH6l8EUAogtMeta } from "D:/GitLab-Runner/build/gV_R9zkRE/0/SmookItalia/smookitalia-nuxt-app/pages/boeken/gedaan.vue?macro=true";
import { default as indexp4uXp4FPFHMeta } from "D:/GitLab-Runner/build/gV_R9zkRE/0/SmookItalia/smookitalia-nuxt-app/pages/boeken/index.vue?macro=true";
import { default as boeking_45aanmaken_45klantitmG4imfgcMeta } from "D:/GitLab-Runner/build/gV_R9zkRE/0/SmookItalia/smookitalia-nuxt-app/pages/boeking-aanmaken-klant.vue?macro=true";
import { default as _91bookingId_93KEjGlahYJ0Meta } from "D:/GitLab-Runner/build/gV_R9zkRE/0/SmookItalia/smookitalia-nuxt-app/pages/boekingen/[bookingId].vue?macro=true";
import { default as _91userId_93lA95OhRaEDMeta } from "D:/GitLab-Runner/build/gV_R9zkRE/0/SmookItalia/smookitalia-nuxt-app/pages/boekingen/admin/[bookingId]/[userId].vue?macro=true";
import { default as indexls0YmEF2G6Meta } from "D:/GitLab-Runner/build/gV_R9zkRE/0/SmookItalia/smookitalia-nuxt-app/pages/boekingen/admin/index.vue?macro=true";
import { default as indexxTXfgJcAzDMeta } from "D:/GitLab-Runner/build/gV_R9zkRE/0/SmookItalia/smookitalia-nuxt-app/pages/boekingen/index.vue?macro=true";
import { default as contactSFgJaQZ1vfMeta } from "D:/GitLab-Runner/build/gV_R9zkRE/0/SmookItalia/smookitalia-nuxt-app/pages/contact.vue?macro=true";
import { default as _91id_93C2831U99d1Meta } from "D:/GitLab-Runner/build/gV_R9zkRE/0/SmookItalia/smookitalia-nuxt-app/pages/eigenaren/[id].vue?macro=true";
import { default as _91id_932rHUjYwdcGMeta } from "D:/GitLab-Runner/build/gV_R9zkRE/0/SmookItalia/smookitalia-nuxt-app/pages/eigenaren/admin/[id].vue?macro=true";
import { default as indexhOAmj9ErseMeta } from "D:/GitLab-Runner/build/gV_R9zkRE/0/SmookItalia/smookitalia-nuxt-app/pages/eigenaren/admin/index.vue?macro=true";
import { default as indexnyULkCPRGuMeta } from "D:/GitLab-Runner/build/gV_R9zkRE/0/SmookItalia/smookitalia-nuxt-app/pages/eigenaren/index.vue?macro=true";
import { default as errorwL5ZXltj0HMeta } from "D:/GitLab-Runner/build/gV_R9zkRE/0/SmookItalia/smookitalia-nuxt-app/pages/error.vue?macro=true";
import { default as favorietenj86lrR8sUwMeta } from "D:/GitLab-Runner/build/gV_R9zkRE/0/SmookItalia/smookitalia-nuxt-app/pages/favorieten.vue?macro=true";
import { default as indexKEa1ABbnbdMeta } from "D:/GitLab-Runner/build/gV_R9zkRE/0/SmookItalia/smookitalia-nuxt-app/pages/index.vue?macro=true";
import { default as _91inquiryId_93bfgIIJcBzXMeta } from "D:/GitLab-Runner/build/gV_R9zkRE/0/SmookItalia/smookitalia-nuxt-app/pages/inkomende-reisaanvragen/[inquiryId].vue?macro=true";
import { default as index8hogwYR0dtMeta } from "D:/GitLab-Runner/build/gV_R9zkRE/0/SmookItalia/smookitalia-nuxt-app/pages/inkomende-reisaanvragen/index.vue?macro=true";
import { default as nieuwsbrief19IEH106jcMeta } from "D:/GitLab-Runner/build/gV_R9zkRE/0/SmookItalia/smookitalia-nuxt-app/pages/nieuwsbrief.vue?macro=true";
import { default as answer_45availabilityJIyZkwfZhjMeta } from "D:/GitLab-Runner/build/gV_R9zkRE/0/SmookItalia/smookitalia-nuxt-app/pages/owner/answer-availability.vue?macro=true";
import { default as reis_45aanvragen_45klant9JYQ653ORNMeta } from "D:/GitLab-Runner/build/gV_R9zkRE/0/SmookItalia/smookitalia-nuxt-app/pages/reis-aanvragen-klant.vue?macro=true";
import { default as reis_45aanvragenffhWr32hCoMeta } from "D:/GitLab-Runner/build/gV_R9zkRE/0/SmookItalia/smookitalia-nuxt-app/pages/reis-aanvragen.vue?macro=true";
import { default as team_45beherenI1N6tGPISCMeta } from "D:/GitLab-Runner/build/gV_R9zkRE/0/SmookItalia/smookitalia-nuxt-app/pages/team-beheren.vue?macro=true";
import { default as winkelwagen7ihmXbEuD9Meta } from "D:/GitLab-Runner/build/gV_R9zkRE/0/SmookItalia/smookitalia-nuxt-app/pages/winkelwagen.vue?macro=true";
export default [
  {
    name: _91level3_93owbQPvqXcFMeta?.name ?? "slug-level2-level3",
    path: _91level3_93owbQPvqXcFMeta?.path ?? "/:slug()/:level2()/:level3()",
    meta: _91level3_93owbQPvqXcFMeta || {},
    alias: _91level3_93owbQPvqXcFMeta?.alias || [],
    redirect: _91level3_93owbQPvqXcFMeta?.redirect,
    component: () => import("D:/GitLab-Runner/build/gV_R9zkRE/0/SmookItalia/smookitalia-nuxt-app/pages/[slug]/[level2]/[level3].vue").then(m => m.default || m)
  },
  {
    name: indexKfbAcadamWMeta?.name ?? "slug-level2",
    path: indexKfbAcadamWMeta?.path ?? "/:slug()/:level2()",
    meta: indexKfbAcadamWMeta || {},
    alias: indexKfbAcadamWMeta?.alias || [],
    redirect: indexKfbAcadamWMeta?.redirect,
    component: () => import("D:/GitLab-Runner/build/gV_R9zkRE/0/SmookItalia/smookitalia-nuxt-app/pages/[slug]/[level2]/index.vue").then(m => m.default || m)
  },
  {
    name: index0oDcl6TXBUMeta?.name ?? "slug",
    path: index0oDcl6TXBUMeta?.path ?? "/:slug()",
    meta: index0oDcl6TXBUMeta || {},
    alias: index0oDcl6TXBUMeta?.alias || [],
    redirect: index0oDcl6TXBUMeta?.redirect,
    component: () => import("D:/GitLab-Runner/build/gV_R9zkRE/0/SmookItalia/smookitalia-nuxt-app/pages/[slug]/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_93BJj8EU8V1TMeta?.name ?? "aankomende-reizen-id",
    path: _91id_93BJj8EU8V1TMeta?.path ?? "/aankomende-reizen/:id()",
    meta: _91id_93BJj8EU8V1TMeta || {},
    alias: _91id_93BJj8EU8V1TMeta?.alias || [],
    redirect: _91id_93BJj8EU8V1TMeta?.redirect,
    component: () => import("D:/GitLab-Runner/build/gV_R9zkRE/0/SmookItalia/smookitalia-nuxt-app/pages/aankomende-reizen/[id].vue").then(m => m.default || m)
  },
  {
    name: index11XR6hdXSNMeta?.name ?? "aankomende-reizen",
    path: index11XR6hdXSNMeta?.path ?? "/aankomende-reizen",
    meta: index11XR6hdXSNMeta || {},
    alias: index11XR6hdXSNMeta?.alias || [],
    redirect: index11XR6hdXSNMeta?.redirect,
    component: () => import("D:/GitLab-Runner/build/gV_R9zkRE/0/SmookItalia/smookitalia-nuxt-app/pages/aankomende-reizen/index.vue").then(m => m.default || m)
  },
  {
    name: token_45verlopenwKj2LOXPmQMeta?.name ?? "aankomende-reizen-token-verlopen",
    path: token_45verlopenwKj2LOXPmQMeta?.path ?? "/aankomende-reizen/token-verlopen",
    meta: token_45verlopenwKj2LOXPmQMeta || {},
    alias: token_45verlopenwKj2LOXPmQMeta?.alias || [],
    redirect: token_45verlopenwKj2LOXPmQMeta?.redirect,
    component: () => import("D:/GitLab-Runner/build/gV_R9zkRE/0/SmookItalia/smookitalia-nuxt-app/pages/aankomende-reizen/token-verlopen.vue").then(m => m.default || m)
  },
  {
    name: _91id_93QEkPIbDPgtMeta?.name ?? "accommodaties-id",
    path: _91id_93QEkPIbDPgtMeta?.path ?? "/accommodaties/:id()",
    meta: _91id_93QEkPIbDPgtMeta || {},
    alias: _91id_93QEkPIbDPgtMeta?.alias || [],
    redirect: _91id_93QEkPIbDPgtMeta?.redirect,
    component: () => import("D:/GitLab-Runner/build/gV_R9zkRE/0/SmookItalia/smookitalia-nuxt-app/pages/accommodaties/[id].vue").then(m => m.default || m)
  },
  {
    name: indexfcmJCZJ0jTMeta?.name ?? "accommodaties",
    path: indexfcmJCZJ0jTMeta?.path ?? "/accommodaties",
    meta: indexfcmJCZJ0jTMeta || {},
    alias: indexfcmJCZJ0jTMeta?.alias || [],
    redirect: indexfcmJCZJ0jTMeta?.redirect,
    component: () => import("D:/GitLab-Runner/build/gV_R9zkRE/0/SmookItalia/smookitalia-nuxt-app/pages/accommodaties/index.vue").then(m => m.default || m)
  },
  {
    name: account_45bewerkenMaRii9PBkrMeta?.name ?? "account-bewerken",
    path: account_45bewerkenMaRii9PBkrMeta?.path ?? "/account-bewerken",
    meta: account_45bewerkenMaRii9PBkrMeta || {},
    alias: account_45bewerkenMaRii9PBkrMeta?.alias || [],
    redirect: account_45bewerkenMaRii9PBkrMeta?.redirect,
    component: () => import("D:/GitLab-Runner/build/gV_R9zkRE/0/SmookItalia/smookitalia-nuxt-app/pages/account-bewerken.vue").then(m => m.default || m)
  },
  {
    name: accountgNZdXx2SySMeta?.name ?? "account",
    path: accountgNZdXx2SySMeta?.path ?? "/account",
    meta: accountgNZdXx2SySMeta || {},
    alias: accountgNZdXx2SySMeta?.alias || [],
    redirect: accountgNZdXx2SySMeta?.redirect,
    component: () => import("D:/GitLab-Runner/build/gV_R9zkRE/0/SmookItalia/smookitalia-nuxt-app/pages/account.vue").then(m => m.default || m)
  },
  {
    name: gedaanH6l8EUAogtMeta?.name ?? "boeken-gedaan",
    path: gedaanH6l8EUAogtMeta?.path ?? "/boeken/gedaan",
    meta: gedaanH6l8EUAogtMeta || {},
    alias: gedaanH6l8EUAogtMeta?.alias || [],
    redirect: gedaanH6l8EUAogtMeta?.redirect,
    component: () => import("D:/GitLab-Runner/build/gV_R9zkRE/0/SmookItalia/smookitalia-nuxt-app/pages/boeken/gedaan.vue").then(m => m.default || m)
  },
  {
    name: indexp4uXp4FPFHMeta?.name ?? "boeken",
    path: indexp4uXp4FPFHMeta?.path ?? "/boeken",
    meta: indexp4uXp4FPFHMeta || {},
    alias: indexp4uXp4FPFHMeta?.alias || [],
    redirect: indexp4uXp4FPFHMeta?.redirect,
    component: () => import("D:/GitLab-Runner/build/gV_R9zkRE/0/SmookItalia/smookitalia-nuxt-app/pages/boeken/index.vue").then(m => m.default || m)
  },
  {
    name: boeking_45aanmaken_45klantitmG4imfgcMeta?.name ?? "boeking-aanmaken-klant",
    path: boeking_45aanmaken_45klantitmG4imfgcMeta?.path ?? "/boeking-aanmaken-klant",
    meta: boeking_45aanmaken_45klantitmG4imfgcMeta || {},
    alias: boeking_45aanmaken_45klantitmG4imfgcMeta?.alias || [],
    redirect: boeking_45aanmaken_45klantitmG4imfgcMeta?.redirect,
    component: () => import("D:/GitLab-Runner/build/gV_R9zkRE/0/SmookItalia/smookitalia-nuxt-app/pages/boeking-aanmaken-klant.vue").then(m => m.default || m)
  },
  {
    name: _91bookingId_93KEjGlahYJ0Meta?.name ?? "boekingen-bookingId",
    path: _91bookingId_93KEjGlahYJ0Meta?.path ?? "/boekingen/:bookingId()",
    meta: _91bookingId_93KEjGlahYJ0Meta || {},
    alias: _91bookingId_93KEjGlahYJ0Meta?.alias || [],
    redirect: _91bookingId_93KEjGlahYJ0Meta?.redirect,
    component: () => import("D:/GitLab-Runner/build/gV_R9zkRE/0/SmookItalia/smookitalia-nuxt-app/pages/boekingen/[bookingId].vue").then(m => m.default || m)
  },
  {
    name: _91userId_93lA95OhRaEDMeta?.name ?? "boekingen-admin-bookingId-userId",
    path: _91userId_93lA95OhRaEDMeta?.path ?? "/boekingen/admin/:bookingId()/:userId()",
    meta: _91userId_93lA95OhRaEDMeta || {},
    alias: _91userId_93lA95OhRaEDMeta?.alias || [],
    redirect: _91userId_93lA95OhRaEDMeta?.redirect,
    component: () => import("D:/GitLab-Runner/build/gV_R9zkRE/0/SmookItalia/smookitalia-nuxt-app/pages/boekingen/admin/[bookingId]/[userId].vue").then(m => m.default || m)
  },
  {
    name: indexls0YmEF2G6Meta?.name ?? "boekingen-admin",
    path: indexls0YmEF2G6Meta?.path ?? "/boekingen/admin",
    meta: indexls0YmEF2G6Meta || {},
    alias: indexls0YmEF2G6Meta?.alias || [],
    redirect: indexls0YmEF2G6Meta?.redirect,
    component: () => import("D:/GitLab-Runner/build/gV_R9zkRE/0/SmookItalia/smookitalia-nuxt-app/pages/boekingen/admin/index.vue").then(m => m.default || m)
  },
  {
    name: indexxTXfgJcAzDMeta?.name ?? "boekingen",
    path: indexxTXfgJcAzDMeta?.path ?? "/boekingen",
    meta: indexxTXfgJcAzDMeta || {},
    alias: indexxTXfgJcAzDMeta?.alias || [],
    redirect: indexxTXfgJcAzDMeta?.redirect,
    component: () => import("D:/GitLab-Runner/build/gV_R9zkRE/0/SmookItalia/smookitalia-nuxt-app/pages/boekingen/index.vue").then(m => m.default || m)
  },
  {
    name: contactSFgJaQZ1vfMeta?.name ?? "contact",
    path: contactSFgJaQZ1vfMeta?.path ?? "/contact",
    meta: contactSFgJaQZ1vfMeta || {},
    alias: contactSFgJaQZ1vfMeta?.alias || [],
    redirect: contactSFgJaQZ1vfMeta?.redirect,
    component: () => import("D:/GitLab-Runner/build/gV_R9zkRE/0/SmookItalia/smookitalia-nuxt-app/pages/contact.vue").then(m => m.default || m)
  },
  {
    name: _91id_93C2831U99d1Meta?.name ?? "eigenaren-id",
    path: _91id_93C2831U99d1Meta?.path ?? "/eigenaren/:id()",
    meta: _91id_93C2831U99d1Meta || {},
    alias: _91id_93C2831U99d1Meta?.alias || [],
    redirect: _91id_93C2831U99d1Meta?.redirect,
    component: () => import("D:/GitLab-Runner/build/gV_R9zkRE/0/SmookItalia/smookitalia-nuxt-app/pages/eigenaren/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_932rHUjYwdcGMeta?.name ?? "eigenaren-admin-id",
    path: _91id_932rHUjYwdcGMeta?.path ?? "/eigenaren/admin/:id()",
    meta: _91id_932rHUjYwdcGMeta || {},
    alias: _91id_932rHUjYwdcGMeta?.alias || [],
    redirect: _91id_932rHUjYwdcGMeta?.redirect,
    component: () => import("D:/GitLab-Runner/build/gV_R9zkRE/0/SmookItalia/smookitalia-nuxt-app/pages/eigenaren/admin/[id].vue").then(m => m.default || m)
  },
  {
    name: indexhOAmj9ErseMeta?.name ?? "eigenaren-admin",
    path: indexhOAmj9ErseMeta?.path ?? "/eigenaren/admin",
    meta: indexhOAmj9ErseMeta || {},
    alias: indexhOAmj9ErseMeta?.alias || [],
    redirect: indexhOAmj9ErseMeta?.redirect,
    component: () => import("D:/GitLab-Runner/build/gV_R9zkRE/0/SmookItalia/smookitalia-nuxt-app/pages/eigenaren/admin/index.vue").then(m => m.default || m)
  },
  {
    name: indexnyULkCPRGuMeta?.name ?? "eigenaren",
    path: indexnyULkCPRGuMeta?.path ?? "/eigenaren",
    meta: indexnyULkCPRGuMeta || {},
    alias: indexnyULkCPRGuMeta?.alias || [],
    redirect: indexnyULkCPRGuMeta?.redirect,
    component: () => import("D:/GitLab-Runner/build/gV_R9zkRE/0/SmookItalia/smookitalia-nuxt-app/pages/eigenaren/index.vue").then(m => m.default || m)
  },
  {
    name: errorwL5ZXltj0HMeta?.name ?? "error",
    path: errorwL5ZXltj0HMeta?.path ?? "/error",
    meta: errorwL5ZXltj0HMeta || {},
    alias: errorwL5ZXltj0HMeta?.alias || [],
    redirect: errorwL5ZXltj0HMeta?.redirect,
    component: () => import("D:/GitLab-Runner/build/gV_R9zkRE/0/SmookItalia/smookitalia-nuxt-app/pages/error.vue").then(m => m.default || m)
  },
  {
    name: favorietenj86lrR8sUwMeta?.name ?? "favorieten",
    path: favorietenj86lrR8sUwMeta?.path ?? "/favorieten",
    meta: favorietenj86lrR8sUwMeta || {},
    alias: favorietenj86lrR8sUwMeta?.alias || [],
    redirect: favorietenj86lrR8sUwMeta?.redirect,
    component: () => import("D:/GitLab-Runner/build/gV_R9zkRE/0/SmookItalia/smookitalia-nuxt-app/pages/favorieten.vue").then(m => m.default || m)
  },
  {
    name: indexKEa1ABbnbdMeta?.name ?? "index",
    path: indexKEa1ABbnbdMeta?.path ?? "/",
    meta: indexKEa1ABbnbdMeta || {},
    alias: indexKEa1ABbnbdMeta?.alias || [],
    redirect: indexKEa1ABbnbdMeta?.redirect,
    component: () => import("D:/GitLab-Runner/build/gV_R9zkRE/0/SmookItalia/smookitalia-nuxt-app/pages/index.vue").then(m => m.default || m)
  },
  {
    name: _91inquiryId_93bfgIIJcBzXMeta?.name ?? "inkomende-reisaanvragen-inquiryId",
    path: _91inquiryId_93bfgIIJcBzXMeta?.path ?? "/inkomende-reisaanvragen/:inquiryId()",
    meta: _91inquiryId_93bfgIIJcBzXMeta || {},
    alias: _91inquiryId_93bfgIIJcBzXMeta?.alias || [],
    redirect: _91inquiryId_93bfgIIJcBzXMeta?.redirect,
    component: () => import("D:/GitLab-Runner/build/gV_R9zkRE/0/SmookItalia/smookitalia-nuxt-app/pages/inkomende-reisaanvragen/[inquiryId].vue").then(m => m.default || m)
  },
  {
    name: index8hogwYR0dtMeta?.name ?? "inkomende-reisaanvragen",
    path: index8hogwYR0dtMeta?.path ?? "/inkomende-reisaanvragen",
    meta: index8hogwYR0dtMeta || {},
    alias: index8hogwYR0dtMeta?.alias || [],
    redirect: index8hogwYR0dtMeta?.redirect,
    component: () => import("D:/GitLab-Runner/build/gV_R9zkRE/0/SmookItalia/smookitalia-nuxt-app/pages/inkomende-reisaanvragen/index.vue").then(m => m.default || m)
  },
  {
    name: nieuwsbrief19IEH106jcMeta?.name ?? "nieuwsbrief",
    path: nieuwsbrief19IEH106jcMeta?.path ?? "/nieuwsbrief",
    meta: nieuwsbrief19IEH106jcMeta || {},
    alias: nieuwsbrief19IEH106jcMeta?.alias || [],
    redirect: nieuwsbrief19IEH106jcMeta?.redirect,
    component: () => import("D:/GitLab-Runner/build/gV_R9zkRE/0/SmookItalia/smookitalia-nuxt-app/pages/nieuwsbrief.vue").then(m => m.default || m)
  },
  {
    name: answer_45availabilityJIyZkwfZhjMeta?.name ?? "owner-answer-availability",
    path: answer_45availabilityJIyZkwfZhjMeta?.path ?? "/owner/answer-availability",
    meta: answer_45availabilityJIyZkwfZhjMeta || {},
    alias: answer_45availabilityJIyZkwfZhjMeta?.alias || [],
    redirect: answer_45availabilityJIyZkwfZhjMeta?.redirect,
    component: () => import("D:/GitLab-Runner/build/gV_R9zkRE/0/SmookItalia/smookitalia-nuxt-app/pages/owner/answer-availability.vue").then(m => m.default || m)
  },
  {
    name: reis_45aanvragen_45klant9JYQ653ORNMeta?.name ?? "reis-aanvragen-klant",
    path: reis_45aanvragen_45klant9JYQ653ORNMeta?.path ?? "/reis-aanvragen-klant",
    meta: reis_45aanvragen_45klant9JYQ653ORNMeta || {},
    alias: reis_45aanvragen_45klant9JYQ653ORNMeta?.alias || [],
    redirect: reis_45aanvragen_45klant9JYQ653ORNMeta?.redirect,
    component: () => import("D:/GitLab-Runner/build/gV_R9zkRE/0/SmookItalia/smookitalia-nuxt-app/pages/reis-aanvragen-klant.vue").then(m => m.default || m)
  },
  {
    name: reis_45aanvragenffhWr32hCoMeta?.name ?? "reis-aanvragen",
    path: reis_45aanvragenffhWr32hCoMeta?.path ?? "/reis-aanvragen",
    meta: reis_45aanvragenffhWr32hCoMeta || {},
    alias: reis_45aanvragenffhWr32hCoMeta?.alias || [],
    redirect: reis_45aanvragenffhWr32hCoMeta?.redirect,
    component: () => import("D:/GitLab-Runner/build/gV_R9zkRE/0/SmookItalia/smookitalia-nuxt-app/pages/reis-aanvragen.vue").then(m => m.default || m)
  },
  {
    name: team_45beherenI1N6tGPISCMeta?.name ?? "team-beheren",
    path: team_45beherenI1N6tGPISCMeta?.path ?? "/team-beheren",
    meta: team_45beherenI1N6tGPISCMeta || {},
    alias: team_45beherenI1N6tGPISCMeta?.alias || [],
    redirect: team_45beherenI1N6tGPISCMeta?.redirect,
    component: () => import("D:/GitLab-Runner/build/gV_R9zkRE/0/SmookItalia/smookitalia-nuxt-app/pages/team-beheren.vue").then(m => m.default || m)
  },
  {
    name: winkelwagen7ihmXbEuD9Meta?.name ?? "winkelwagen",
    path: winkelwagen7ihmXbEuD9Meta?.path ?? "/winkelwagen",
    meta: winkelwagen7ihmXbEuD9Meta || {},
    alias: winkelwagen7ihmXbEuD9Meta?.alias || [],
    redirect: winkelwagen7ihmXbEuD9Meta?.redirect,
    component: () => import("D:/GitLab-Runner/build/gV_R9zkRE/0/SmookItalia/smookitalia-nuxt-app/pages/winkelwagen.vue").then(m => m.default || m)
  }
]