import useStore from '~/store/store';

export default defineNuxtPlugin(() => {
  const store = useStore();

  fetchData('/filter').then((res: any) => {
    store.setAvailableFilters(res);
  });

  fetchData('/filter/regions').then((res: any) => {
    store.setRegionOptions(res);
  });
});
