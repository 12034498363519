<template>
  <div class="row">
    <div class="col-xs-12 col-sm-10">
      <div
        class="heading2 q-mb-lg"
        v-text="content.titel"
        v-if="content.titel"
      />
      <span
        v-if="content.tekst"
        v-html="content.tekst"
        class="light raw"
      />

      <iframe
        ref="componentScript"
        width="100%"
        :height="iFrameHeight"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
const props = defineProps({
  content: {
    type: Object as PropType<EmbedScriptContentBlock>,
    required: true,
  },
});

const iFrameHeight = props.content.hoogteInPx + 'px' || '500px';
const componentScript = ref();

onMounted(async () => {
  var rawframe = componentScript.value;
  var framedoc = rawframe.contentDocument;
  if (!framedoc && rawframe.contentWindow) {
    framedoc = rawframe.contentWindow.document;
  }
  var script = document.createElement('script');
  script.type = 'text/javascript';
  script.src = props.content.scriptUrl;
  framedoc.body.appendChild(script);
});
</script>

<style lang="scss" scoped>
iframe {
  border: none;
}
</style>
