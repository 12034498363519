<template>
  <div class="tabContainer">
    <div
      class="tab"
      v-for="tab in options"
      :key="tab.value"
      v-text="tab.label"
      @click="emit('update:model-value', tab.value)"
      :class="{ active: modelValue === tab.value }"
    />
  </div>
</template>

<script setup lang="ts">
interface Tab {
  label: string;
  value: any;
}

defineProps({
  options: {
    type: Array as PropType<Tab[]>,
    required: true,
  },

  modelValue: {
    required: false,
  },
});

const emit = defineEmits(['update:model-value']);
</script>

<style lang="scss" scoped>
@import '~/styles/variables.sass';

.tabContainer {
  display: flex;
  gap: 4px;
  justify-content: space-between;
  border: 2px solid $backgrounds-150;
  border-radius: 45px;
  padding: 2px;
  width: fit-content;

  .tab {
    transition: 0.25s;
    cursor: pointer;
    padding: 8px 24px;
    border-radius: 32px;
    border: 2px solid transparent;

    &.active {
      background: $backgrounds-50;
      font-weight: bold;
    }

    &:hover {
      background: $backgrounds-50;
    }
  }
}
</style>
