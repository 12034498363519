<template>
  <div class="QuoteContentBlock grid text-center">
    <HeadingTypeComponent
      class="q-mb-sm"
      :title="content.titel"
      v-if="content.titel"
      :heading-type="content.titelOpmaak"
    />

    <p
      v-if="quote"
      class="q-mt-md"
      v-html="quote"
    />

    <p v-if="content.auteur">{{ content.auteur }}</p>
  </div>
</template>

<script setup lang="ts">
import HeadingTypeComponent from './HeadingTypeComponent.vue';

const props = defineProps({
  content: {
    type: Object as PropType<QuoteContentBlock>,
    required: true,
  },
});

const quote = computed(() => props.content?.quote);
</script>

<style lang="scss" scoped>
.QuoteContentBlock {
  color: $text-medium;
}
</style>
