<template>
  <div
    class="container"
    v-if="isMounted"
  >
    <client-only>
      <l-map
        ref="map"
        v-model:zoom="zoom"
        :center="centerItaly"
        :bounds="bounds"
        :max-bounds="bounds"
        :use-global-leaflet="false"
        :options="{ scrollWheelZoom: false }"
        :max-zoom="10"
        :min-zoom="5.5"
      >
        <l-tile-layer
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          layer-type="base"
          name="OpenStreetMap"
        ></l-tile-layer>
        <l-circle-marker
          v-for="(marker, idx) in markers"
          :lat-lng="marker.center"
          :radius="marker.radius"
          :fill-opacity="marker.fillOpacity"
          :fill-color="marker.fillColor"
          :stroke="false"
          :key="idx"
        >
          <l-popup
            class="mapPopup"
            :options="{ maxWidth: 400 }"
          >
            <div
              class="row"
              @click="markerClicked(marker)"
            >
              <div class="col-5">
                <Image :src="marker.imageUrl" />
              </div>

              <div class="col-7">
                <div class="q-ml-xs">
                  <div class="accoTitle">
                    {{ marker.tooltip }}
                  </div>
                  <div>
                    {{ marker.region }}
                  </div>
                </div>
              </div>
            </div>
          </l-popup>
        </l-circle-marker>
      </l-map>
    </client-only>
  </div>
</template>

<script setup lang="ts">
import 'leaflet/dist/leaflet.css';
import { LMap, LTileLayer, LCircleMarker, LPopup } from '@vue-leaflet/vue-leaflet';
import { useRouter } from 'vue-router';

const router = useRouter();

interface Point {
  lat: string;
  long: string;
  name: string;
  slug: string;
}

const props = defineProps({
  points: {
    type: Array as () => Point[],
    default: () => [],
  },
});

const zoom = ref(5.5);
const centerItaly = ref([42.9, 12.5]);
const bounds = [
  [34.09639151738138, 6.375072564727065],
  [51.1421235943447, 20.327311347807328],
];

const map = ref<typeof LMap | null>(null);

const markers = computed<Marker[]>(() =>
  props.points.map((point: Point) => ({
    center: [point.lat, point.long],
    radius: 8,
    fillColor: 'black',
    fillOpacity: 0.8,
    stroke: false,
    tooltip: point.name,
    slug: point.slug,
    region: point.region,
    imageUrl: point.imageUrl,
  }))
);

const isMounted = ref(false);
onMounted(() => {
  nextTick(() => {
    isMounted.value = true;
  });
});

const markerClicked = (marker: Marker) => {
  // Construct the route string with the villa's slug
  const route = `/accommodaties/${marker.slug}`;

  // Use router.push to navigate to the detail page
  router.push(route);
};

// function updateBounds(bounds: L.LatLngBounds) {
//   console.log(bounds);
// }
</script>

<style lang="scss" scoped>
.container {
  width: auto;
  height: 95vh;
}

.mapPopup {
  cursor: pointer;

  .accoTitle {
    color: $calm-blue-500;
  }
}
</style>
