<template>
  <div class="row">
    <div class="col-12">
      <div
        class="heading2 q-mb-lg"
        v-text="content.titel"
        v-if="content.titel"
      />

      <div>
        <div
          class="q-mb-sm light raw"
          v-html="embed"
          v-if="embed"
        />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
const props = defineProps({
  content: {
    type: Object as PropType<EmbedContentBlock>,
    required: true,
  },
});

const embed = ref('');

onMounted(() => {
  embed.value = props.content.tekst;
});
</script>

<style lang="scss" scoped>
.embed {
  width: 100%;
}
</style>
