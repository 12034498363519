<template>
  <div class="TextInput">
    <span class="label">
      {{ labelText }}
    </span>
    <q-input
      outlined
      dense
      v-model="internalValue"
      v-bind="$attrs"
      :rules="rules"
      class="q-mt-xs"
    >
      <template
        v-for="(_, slot) of $slots"
        #[slot]="scope"
      >
        <slot
          :name="slot"
          v-bind="scope || {}"
        />
      </template>
    </q-input>
  </div>
</template>

<script setup lang="ts">
import { ref, watch, computed } from 'vue';

const props = defineProps({
  labelText: {
    type: String,
    default: null,
  },

  modelValue: {
    type: [String, Number],
    default: '',
  },

  // Maybe this can go?
  required: {
    type: Boolean,
    default: false,
  },
});

const validations = {
  required: [(val: string) => !!val || 'Field is required'],
  password: [(val: string) => val.length || 'Too short'],
  email: [(val: string) => /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(val) || 'Invalid e-mail'],
};

const rules = computed(() => {
  if (!props.required) return [];
  return validations.required;
});

const internalValue = ref(props.modelValue);

watch(
  () => props.modelValue,
  (value) => {
    internalValue.value = value;
  }
);
</script>
