import { defineStore } from 'pinia';
import { USER_LEVELS } from '../config';
import { i18n } from '~/plugins/i18n';

export default defineStore('authStore', () => {
  const showLoginModal = ref(false);
  const loginFlowState = ref(3);
  const user = ref<User>({});
  const token = ref('');
  const hasValidAccount = computed(() => !!user.value.email && !!user.value.phonePrivate && !!user.value.zipCode && !!user.value.place);
  const isBookingUser = computed(() => user.value?.isVerified == false || (user.value?.email && user.value?.isVerified == undefined));
  // const isBookingUser = computed(() => user.value?.userLevel == USER_LEVELS.Booking);

  const c = useCookie('token');

  function setShowLoginModal(val: boolean) {
    showLoginModal.value = val;
  }

  function setLoginFlowState(value: number) {
    loginFlowState.value = value;
  }

  function setStoreUser(userData: User) {
    user.value = userData;

    if (userData.userLevel == USER_LEVELS.Owner) {
      i18n.global.locale.value = 'en';
    }
  }

  function setToken(tokenValue: string) {
    token.value = tokenValue;
    if (c.value != tokenValue) {
      c.value = tokenValue;
    }
  }

  return {
    loginFlowState,
    setLoginFlowState,
    showLoginModal,
    setShowLoginModal,
    user,
    setStoreUser,
    token,
    setToken,
    hasValidAccount,
    isBookingUser,
  };
});
