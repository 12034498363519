<template>
  <DialogComponent
    :title="modalTitle"
    @close="onModalClose"
  >
    <AuthRegister
      v-if="loginFlowState === 0"
      @logged-in="onModalClose"
      @registered="authStore.setLoginFlowState(1)"
    />
    <div
      bordered
      flat
      class="loginContainer"
      v-else-if="loginFlowState === 1"
    >
      <div
        class="q-mt-md"
        v-t="'login.emailSent'"
      ></div>

      <q-btn
        color="primary"
        class="q-mt-md"
        @click="authStore.setLoginFlowState(3)"
        v-t="'login.toLogin'"
      >
      </q-btn>
    </div>
    <div
      v-else-if="loginFlowState === 2"
      @verified="authStore.setLoginFlowState(7)"
    >
      <div class="Verify">
        <q-spinner
          color="primary"
          v-if="isLoadingVerify"
        />
        <div
          v-else-if="verifyErorMessage"
          v-text="verifyErorMessage"
        />
      </div>
    </div>
    <AuthLogin
      v-else-if="loginFlowState === 3"
      @logged-in="onLogin"
      @not-verified="authStore.setLoginFlowState(8)"
      @register="authStore.setLoginFlowState(0)"
      @forgot-password="authStore.setLoginFlowState(4)"
    />

    <AuthForgotPassword
      v-else-if="loginFlowState === 4"
      @reset-password="authStore.setLoginFlowState(3)"
    />

    <AuthResetPassword
      v-else-if="loginFlowState === 5 || loginFlowState === 6"
      @password-changed="authStore.setLoginFlowState(3)"
    />

    <div v-else-if="loginFlowState === 7">
      <p v-t="'login.accountActivated'" />
      <q-btn
        color="primary"
        class="q-mt-md"
        v-t="'login.toLogin'"
        @click="authStore.setLoginFlowState(3)"
      />
    </div>

    <div v-else-if="loginFlowState === 8">
      <p v-t="'login.notVerified'" />
      <q-btn
        color="primary"
        class="q-mt-md"
        v-t="'login.toLogin'"
        @click="authStore.setLoginFlowState(3)"
      />
    </div>
  </DialogComponent>
</template>

<script setup lang="ts">
import useAuthStore from '~/store/auth';
import routeNames from '~/utils/routeNames';
import { useI18n } from 'vue-i18n';
// import { verify } from 'crypto';

const { t } = useI18n();
const authStore = useAuthStore();
const router = useRouter();

const titleMap = {
  '0': t('login.modalTitles.register'),
  '1': t('login.modalTitles.accountCreated'),
  '2': t('login.modalTitles.emailVerify'),
  '3': t('login.modalTitles.Login'),
  '4': t('login.modalTitles.forgotPassword'),
  '5': t('login.modalTitles.editPassword'),
  '6': t('login.modalTitles.setPassword'),
  '7': t('login.modalTitles.emailVerified'),
  '8': t('login.modalTitles.emailNotVerified'),
};

const modalTitle = computed(() => titleMap[loginFlowState.value.toString()]);
const loginFlowState = computed(() => authStore.loginFlowState);

function onLogin() {
  if (route.name !== 'accommodaties-id') {
    router.push(routeNames.account);
  }
  onModalClose();
}

const isLoadingVerify = ref(false);
const verifyErorMessage = ref('');
const route = useRoute();

if (loginFlowState.value === 2) {
  nextTick(() => {
    verifyAccount();
  });
}

function onModalClose() {
  authStore.setLoginFlowState(3);
  authStore.setShowLoginModal(false);
}

async function verifyAccount() {
  isLoadingVerify.value = true;
  const token = encodeURIComponent(route.query?.verify?.toString() || '');

  const response = await fetchData('/authentication/verification', {
    method: 'post',
    body: { token },
  });

  if (!response) {
    verifyErorMessage.value = 'Iets ging fout tijdens de verificatie van je account';
  } else if (!response?.user) {
    verifyErorMessage.value = response?.message;
  } else {
    authStore.setLoginFlowState(7);
  }

  isLoadingVerify.value = false;
}
</script>
