import { defineStore } from 'pinia';

export default defineStore('shoppingCartStore', () => {
  const { gtag } = useGtag();
  const cookie = useCookie<ShoppingCartItem[] | string>('jfi_shoppingCart');
  const currentShoppingCart = reactive<ShoppingCartItem[]>((cookie.value as ShoppingCartItem[]) || []);
  const shoppingCartPrice = ref<BookingPricing>();

  watch(currentShoppingCart, async (value) => {
    cookie.value = JSON.stringify(value);
    shoppingCartPrice.value = (await getEstimatedPrice(value)) as BookingPricing;
  });

  function emptyShoppingCart() {
    currentShoppingCart.splice(0);
  }

  function createShoppingCartEntry(item: ShoppingCartItem) {
    const entry = item;
    // entry.guests = [];
    return entry;
  }

  function addToShoppingCart(item: ShoppingCartItem) {
    gtag('event', 'add_to_cart', {
      items: [
        {
          item_id: item.id,
          item_name: item.name,
        },
      ],
    });
    currentShoppingCart.push(createShoppingCartEntry(item));
  }

  function removeFromShoppingCart(idx: number) {
    const item = currentShoppingCart[idx];
    gtag('event', 'remove_from_cart', {
      items: [
        {
          item_id: item.id,
          item_name: item.name,
        },
      ],
    });
    if (idx === 0 && currentShoppingCart.length === 1) {
      currentShoppingCart.splice(-1);
    } else {
      currentShoppingCart.splice(idx, 1);
    }
  }

  return { currentShoppingCart, addToShoppingCart, removeFromShoppingCart, emptyShoppingCart, shoppingCartPrice };
});
