export default {
  categories: {
    description: 'Description',
    facilities: 'Facilities',
    location: 'Location',
    availability: 'Availability',
    doNotMiss: 'Do Not Miss',
    reviews: 'Reviews',
  },
};
