<template>
  <q-dialog
    :persistent="persistent"
    :maximized="isMobile && !disableMaximize"
    :class="{ maximized: isMobile && !disableMaximize }"
    @hide="emit('close')"
  >
    <q-card class="content">
      <q-btn
        class="closeButton textLowImportance"
        v-if="!title"
        v-close-popup
        icon="icon-close"
        size="md"
        flat
        round
        dense
        @click="emit('close')"
      />

      <q-card-section
        class="titleSection"
        v-if="title"
      >
        <div class="titleContainer row">
          <div class="row col-10">
            <div>
              <div
                class="heading2"
                v-text="title"
              />
            </div>
            <q-space />
          </div>

          <q-btn
            class="closeButton textLowImportance col-2"
            v-close-popup
            icon="icon-close"
            size="md"
            flat
            round
            dense
            @click="emit('close')"
          />

          <!-- <div class="col-2"> -->
          <!-- </div> -->
        </div>
      </q-card-section>
      <!-- <q-btn class="closeButton textLowImportance" v-close-popup icon="icon-close" size="md" flat round dense @click="emit('close')" /> -->

      <q-card-section class="dialogBody">
        <slot />
      </q-card-section>
    </q-card>
  </q-dialog>
</template>

<script setup lang="ts">
const isMobile = getIsMobile();

defineProps({
  persistent: {
    type: Boolean,
    default: false,
  },
  title: {
    type: String,
    default: '',
  },
  hideActions: {
    type: Boolean,
    default: false,
  },
  disableMaximize: {
    type: Boolean,
    default: false,
  },
});

const emit = defineEmits(['close']);
</script>

<style lang="scss" scoped>
@import '~/styles/variables.sass';

.closeButton {
  padding: 4px !important;
  z-index: 999999;
  position: absolute;
  right: 15px;
  top: 15px;
  width: auto;
  color: $text-grey;
}

.dialogBody {
  padding-top: 0;
}

.actions {
  border-top: 1px solid #e5e5e5;
}

.content {
  padding: 32px;
  border-radius: 8px;
}

@media (max-width: $screen-xs) {
  .maximized {
    .titleSection {
      position: fixed;
      width: 100%;
      background: white;
      z-index: 2;
      border-bottom: 1px solid $sea-blue-300;
    }

    .dialogBody {
      margin-top: 80px;
      padding: 6px 24px;
    }
  }

  .content {
    padding: 0;
  }
}
</style>
store/store
