export default {
  breadcrumbs: {
    home: 'Home',
    myAccount: 'Mijn account',
    editAccount: 'Gegevens aanpassen',
    travelApplications: 'Inkomende reisaanvragen',
    travelApplication: 'Inkomende reisaanvraag',
    upcomingTrips: 'Aankomende reizen',
  },

  favorites: {
    title: 'Favorieten',
    description: 'Bekijk je opgeslagen favorieten',
  },

  inquiryForCustomer: {
    title: 'Aanmaken reisadvies',
    description: 'Maak reisadvies voor nieuwe of bestaande klanten',
  },

  bookingForCustomer: {
    title: 'Aanmaken boeking',
    description: 'Maak een boeking aan voor nieuwe of bestaande klanten',
  },

  incomingTrips: {
    title: 'Reisadviezen te behandelen / in behandeling',
    description: 'Zoek naar klanten met uitgebrachte reisadviezen',
  },

  bookings: {
    title: 'Opties en boekingen',
    description: 'Bekijk je boekingen en opties',
  },

  owners: {
    title: 'Boekingskalender. (Voorheen portale online)',
    description: 'Update hier je agenda!',
  },

  logOut: {
    title: 'Uitloggen',
    description: 'Log je account uit',
  },

  changePassword: {
    title: 'Wachtwoord veranderen',
    description: 'Verander je wachtwoord',
  },

  editAccount: {
    title: 'Account beheren',
    description: 'Pas je gegevens aan',
    male: 'Man',
    female: 'Vrouw',
    other: 'Anders',
    firstName: 'Voornaam',
    between: 'Tussenvoegsel',
    lastName: 'Achternaam',
    birthDate: 'Geboortedatum',
    emailAddress: 'E-mailadres',
    place: 'Plaats',
    street: 'Straat',
    zipCode: 'Postcode',
    number: 'Huisnummer',
    addition: 'Toevoeging',
    country: 'Land',
    save: 'Gegevens opslaan',
    telephone: 'Telefoonnummer',
    password: 'Wachtwoord',
    repeatPassword: 'Herhaal password',
  },

  travelApplications: {
    addTravelApplication: 'Reisaanvraag toevoegen',
    open: 'Openen',
  },
};
