import useElocusStore from '../store/elocus';

export default defineNuxtRouteMiddleware(async (to, from) => {
  if (to.path.endsWith('/') && to.path.length > 1) {
    return navigateTo(to.path.slice(0, -1), { redirectCode: 301 });
  }

  const s = useElocusStore();
  const redirectIdx = s.redirectTriggers.indexOf(to.path);
  if (redirectIdx > -1) {
    return navigateTo(s.redirects[redirectIdx]?.destination, { redirectCode: 301 });
  }
});
