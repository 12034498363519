<template>
  <div class="LoginPage">
    <div
      bordered
      flat
    >
      <div class="q-mt-md">
        <TextInput
          :label-text="t('account.editAccount.firstName')"
          v-model="data.firstName"
          bottom-slots
          :error="!!v$.firstName.$errors.length"
          :error-message="!!v$.firstName.$errors.length ? v$.firstName.$errors[0].$message : ''"
        />
      </div>

      <div class="q-mt-md">
        <TextInput
          :label-text="t('account.editAccount.lastName')"
          v-model="data.lastName"
          bottom-slots
          :error="!!v$.lastName.$errors.length"
          :error-message="!!v$.lastName.$errors.length ? v$.lastName.$errors[0].$message : ''"
        />
      </div>

      <div class="q-mt-md">
        <TextInput
          :label-text="t('account.editAccount.emailAddress')"
          v-model="data.email"
          type="email"
          bottom-slots
          :error="!!v$.email.$errors.length"
          :error-message="!!v$.email.$errors.length ? v$.email.$errors[0].$message : ''"
        />
      </div>

      <div class="q-mt-md">
        <TextInput
          :label-text="t('account.editAccount.telephone')"
          v-model="data.phonePrivate"
          type="email"
          bottom-slots
          :error="!!v$.phonePrivate.$errors.length"
          :error-message="!!v$.phonePrivate.$errors.length ? v$.phonePrivate.$errors[0].$message : ''"
        />
      </div>

      <div class="q-mt-md">
        <TextInput
          :label-text="t('account.editAccount.password')"
          v-model="data.password"
          :type="showPassword ? 'text' : 'password'"
          bottom-slots
          :error="!!v$.password.$errors.length"
          :error-message="!!v$.password.$errors.length ? v$.password.$errors[0].$message : ''"
        >
          <template #append>
            <q-icon
              :name="showPassword ? 'visibility_off' : 'visibility'"
              class="cursor-pointer"
              @click="showPassword = !showPassword"
            />
          </template>
        </TextInput>
      </div>

      <div class="q-mt-md">
        <TextInput
          :label-text="t('account.editAccount.repeatPassword')"
          v-model="data.passwordConfirm"
          :type="showConfirmPassword ? 'text' : 'password'"
          bottom-slots
          :error="!!v$.passwordConfirm.$errors.length"
          :error-message="!!v$.passwordConfirm.$errors.length ? v$.passwordConfirm.$errors[0].$message : ''"
        >
          <template #append>
            <q-icon
              :name="showConfirmPassword ? 'visibility_off' : 'visibility'"
              class="cursor-pointer"
              @click="showConfirmPassword = !showConfirmPassword"
            />
          </template>
        </TextInput>
      </div>

      <div class="q-mt-md">
        <q-btn
          color="primary"
          v-t="'login.register'"
          @click.once="register"
        />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { reactive, ref, computed } from 'vue';
import { useVuelidate } from '@vuelidate/core';
import { required, minLength, maxLength, passwordValidation, email, sameAs } from '~/utils/validations';
import { useI18n } from 'vue-i18n';

const emit = defineEmits(['registered']);
const { t } = useI18n();
const data = reactive({
  returnUrl: '',
  firstName: '',
  lastName: '',
  email: '',
  password: '',
  passwordConfirm: '',
});

const rules = computed(() => ({
  firstName: { required, $autoDirty: true },
  lastName: { required, $autoDirty: true },
  email: { required, email, $autoDirty: true },
  phonePrivate: { required, $autoDirty: true },
  password: {
    required,
    minLength: minLength(5),
    maxLength: maxLength(25),
    passwordValidation,
    $autoDirty: true,
  },
  passwordConfirm: { required, sameAsPassword: sameAs(data.password), $autoDirty: true },
}));

const v$ = useVuelidate(rules, data);

const showPassword = ref(false);
const showConfirmPassword = ref(false);

async function register() {
  const isValid = await v$.value.$validate();
  if (!isValid) {
    return;
  }

  try {
    const result: RegisterResponse = (await fetchData('/authentication/registration', { method: 'post', body: data })) as RegisterResponse;
    if (!result.user) {
      setToast({
        type: 'negative',
        message: result.message,
      });
    } else {
      emit('registered');
    }
  } catch (e) {
    setToast({
      type: 'negative',
      message: e.response?.data?.detail || 'Inloggen niet gelukt',
    });
  }
}
</script>

<style scoped>
.LoginPage {
  height: 100%;
  width: 350px;
}
.loginContainer {
  max-width: 550px;
  margin: auto;
  margin-top: 10%;
  top: 50%;
  padding: 100px;
}

hr {
  border: 1x solid #a3a8ad;
}

.socialLogin {
  color: black;
}

@media (max-width: $screen-xs) {
  .LoginPage {
    width: 100%;
  }
}
</style>
