export default {
  // login
  logIn: 'Log In',
  forgotPassword: 'Forgot Password',
  loginGoogle: 'Log In with Google',
  loginFacebook: 'Log In with Facebook',
  register: 'Register',
  stayLoggedIn: 'Stay logged In',
  noAccount: 'No account yet?',
  emailSent: 'An email has been sent to activate your account.',

  // Forgot password
  enterMail: 'Enter your email so that an email can be sent with a link to reset your password.',
  reset: 'Reset',
  submit: 'Submit',
  errorMessagePassword: 'Failed to edit password',

  // Verify email
  verifyMail: 'Verify Email',
  accountActivated: 'Your account is activated. Click the button below to log in.',
  toLogin: 'To Login',

  // not verified account
  notVerified: 'We have sent you an email for verification, please also check your junk/spam folder if necessary.',

  modalTitles: {
    register: 'Register',
    accountCreated: 'Account created',
    emailVerify: 'Verify email',
    Login: 'Log in',
    forgotPassword: 'Forgot password',
    editPassword: 'Edit password',
    setPassword: 'Set password',
    emailVerified: 'Email verified',
    emailNotVerified: 'Email not verified',
  },
};
