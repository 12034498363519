<template>
  <div class="AfbeeldingBannerContentBlock">
    <Image
      :src="getImageUrl(content.afbeelding, windowWidth, 1200)"
      :alt="content.afbeeldingAltTekst"
    >
      <div class="text">
        <span v-html="content.titel" />

        <ContentblokkenButton
          :name="content.link.name"
          :link="content.link.link"
          :target="content.link.target"
        />
      </div>
    </Image>
  </div>
</template>

<script setup lang="ts">
// TODO: Banner type maken
//
// import { AfbeeldingContentBlockType } from '~/utils/contentblockTypes';
defineProps({
  content: {
    type: Object,
    required: true,
  },
});
const windowWidth = 1400;
// const windowWidth = window ? window?.innerWidth : 1400;
</script>

<style lang="scss" scoped>
@import '~/styles/variables.sass';

.AfbeeldingBannerContentBlock {
  :deep(.Image) {
    border-radius: 0;
    height: 500px;

    .q-img__content > div {
      background: none;
    }
  }

  :deep(.text) {
    text-align: center;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
    font-size: 4rem;
    display: flex;
    font-weight: 500;
    line-height: 120%;
    text-shadow: 0px 4px 9px rgba(0, 0, 0, 0.2), 0px 1px 1px rgba(55, 55, 55, 0.2);

    strong {
      font-weight: bolder;
    }
  }
}

@media (max-width: $screen-xs) {
  // img {
  //   width: 100%;

  // }
  .text {
    font-size: 2rem !important;
  }
}
</style>
