export const guestsFormatter = (adults: number = 0, children: number = 0, babies: number = 0, bedrooms: number = 0) => {
  let string = '';
  if (adults === 1) string += `${adults} volwassene`;
  else if (adults > 1) string += `${adults} volwassenen`;

  if (children === 1) string += `, ${children} kind`;
  if (children > 1) string += `, ${children} kinderen`;

  if (babies === 1) string += `, ${babies} baby`;
  if (babies > 1) string += `, ${babies} baby's`;

  if (bedrooms === 1) string += `, ${bedrooms} slaapkamer`;
  else if (bedrooms > 1) string += `, ${bedrooms} slaapkamers`;

  return string || 'Gasten en slaapkamers';
};
