<template>
  <div
    v-if="!cookiesClicked"
    class="CookieNotification"
  >
    <div class="content">
      <div class="inline-elements">
        <div class="text-button-container row q-col-gutter-sm">
          <div class="col-xs-12 col-md-8">
            <p>
              Deze website maakt gebruik van cookies voor een goede werking van de site. Klik je op akkoord? Dan stem je toe dat wij cookies
              plaatsen. Voor meer informatie, lees onze
              <a
                href="/privacy"
                class="textLink q-mt-sm"
                >privacyverklaring</a
              >
            </p>
          </div>
          <div class="button-container col-xs-12 col-md-4">
            <q-btn @click="acceptOnlyFunctionalCookies"> Alleen functionele cookies </q-btn>

            <q-btn
              @click="acceptCookies"
              color="primary"
            >
              <q-icon name="icon-check" /> Accepteren</q-btn
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
const { grantConsent, gtag } = useGtag();

function acceptTracking() {
  grantConsent();

  // Misschien dat dit nodig is:
  // gtagDataLayer('consent', 'update', {
  //   ad_storage: 'granted',
  //   ad_user_data: 'granted',
  //   ad_personalization: 'granted',
  // });

  gtag('consent', 'update', {
    ad_storage: 'granted',
    ad_user_data: 'granted',
    ad_personalization: 'granted',
    analytics_storage: 'granted',
  });
}

const acceptCookies = () => {
  acceptTracking();
  // 1 year in seconds
  const c = useCookie('smook_cookiesClicked', { maxAge: 31556926 });
  c.value = 'true';
  const trackingCookie = useCookie('smook_trackingEnabled', { maxAge: 31556926 });
  trackingCookie.value = 'true';
};

const acceptOnlyFunctionalCookies = () => {
  // 1 year in seconds
  const c = useCookie('smook_cookiesClicked', { maxAge: 31556926 });
  c.value = 'true';
};

const cookiesClicked = useCookie('smook_cookiesClicked');
const initialTrackingCookie = useCookie('smook_trackingEnabled');

onMounted(() => {
  if (initialTrackingCookie.value) {
    acceptTracking();
  }
});
</script>

<style lang="scss" scoped>
.CookieNotification {
  display: flex;
  width: 100%;
  background: white;
  bottom: 0;
  position: fixed;
  padding: 32px 200px;

  z-index: 99;

  flex-direction: row;
  align-items: center;
  gap: 32px;

  .content {
    max-width: 1300px;
  }

  .q-btn {
    width: auto;
  }

  .text-button-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    flex-grow: 1;
  }

  .text-container {
    flex: 1;
  }

  .button-container {
    display: flex;
    gap: 16px;
  }
}

@media (max-width: 1200px) {
  .CookieNotification {
    padding: 32px;
  }
}

@media (max-width: $screen-xs) {
  .CookieNotification {
    bottom: 64px;
  }
}
</style>
