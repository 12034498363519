<template>
  <div class="row">
    <div class="col-xs-12">
      <div class="heading2 q-mb-lg">
        {{ content.titel }}
      </div>

      <div
        v-for="(q, idx) in questions"
        :key="idx"
        v-if="questions.length > 0"
        class="vraag q-mt-lg"
      >
        <q-expansion-item
          expand-separator
          :label="q.content.vraag"
        >
          <template #header>
            <div
              v-text="q.content.vraag"
              class="heading3 expansionHeader"
            />
          </template>

          <template #default>
            <div class="row antwoord">
              <div
                class="col-xs-12 col-sm-10 raw"
                v-html="q.content.antwoord"
              />
            </div>
          </template>
        </q-expansion-item>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
const props = defineProps({
  content: {
    type: Object as PropType<VeelgesteldeVragenContentBlock>,
    required: true,
  },
});

interface Question {
  content: {
    vraag: string;
    antwoord: string;
  };
}

const config = useRuntimeConfig();
const FAQ_URL = config.public.apiBase + '/api/page/2111/';

const allQuestions = props.content.vragen.split(', ');
const questions = ref<Question[]>([]);

for (let q of allQuestions) {
  const { data }: { data: Question[] } = await useFetch(FAQ_URL + q);
  questions.value.push(data.value);
}
</script>

<style lang="scss" scoped>
@import '~/styles/variables.sass';

.vraag {
  border: 1px solid $backgrounds-150;
  border-radius: 8px;

  .expansionHeader {
    width: 100%;
    padding: 32px 40px 32px 26px;
  }

  .antwoord {
    padding: 8px 24px 24px 40px;
  }
}

@media (max-width: $screen-xs) {
  .expansionHeader {
    font-size: 18px;
    font-weight: initial;
    padding: 8px 12px !important;
  }

  .antwoord {
    padding: 24px !important;
  }
}
</style>
