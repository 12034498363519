import { getImageUrl } from './getImageUrl';

interface Metadata {
  titel: string;
  metaTitel?: string;
  indexeren: string;
  canonical: string;
  omschrijving: string;
  afbeelding: number;
}

export const setMetaData = (metadata: Metadata) => {
  const image = metadata?.afbeelding || 2;
  const imageUrl = typeof image == 'number' ? getImageUrl(image) : image;
  let robots;
  if (metadata?.index) {
    robots = metadata.index === 'noindex' ? 'noindex' : 'index';
  }
  let mappedMeta = {
    ogType: 'website',
    ogImage: imageUrl,
    title: 'Ecco Italia',
    ogTitle: 'Ecco Italia',
    ogLocale: 'nl',
    description: metadata?.omschrijving,
    robots,
    // ogUrl: `https://cre8ionvue.tc8l.dev${path}`,
  };

  if (metadata?.titel) {
    mappedMeta.title = metadata.titel.replace(/<[^>]*>?/gm, '');
  } else if (metadata?.metaTitel) {
    mappedMeta.title = metadata.metaTitel.replace(/<[^>]*>?/gm, '');
  }
  mappedMeta.ogTitle = mappedMeta.title;
  return useSeoMeta({ ...mappedMeta });
};
