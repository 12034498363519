<template>
  <div class="ForgotPassword">
    <p v-t="'login.enterMail'" />
    <TextInput
      v-model="email"
      label-text="Email"
    />
    <q-btn
      class="q-mt-lg"
      @click="forgotPassword"
      color="primary"
      v-t="'login.reset'"
      :disable="email.length < 2"
    />
  </div>
</template>

<script setup lang="ts">
import { ref } from 'vue';

const email = ref('');
const emit = defineEmits(['reset-password']);

async function forgotPassword() {
  try {
    (await fetchData('/authentication/forgotpassword', { method: 'post', body: { email } })) as ForgotPasswordResponse;
    setToast({ message: 'Wachtwoord vergeten e-mail verstuurd' });
    emit('reset-password');
  } catch (e: any) {
    if (e.response?.data?.errors) {
      Object.values(e.response.data.errors).forEach((error) => {
        setToast({
          type: 'negative',
          message: error,
        });
      });
    }
  }
}
</script>

<style scoped>
.ForgotPassword {
  max-width: 560px;
}

hr {
  border: 1x solid #a3a8ad;
}

.socialLogin {
  color: black;
}
</style>
