<template>
  <component
    :is="seoHeadingtype"
    v-if="title"
    :class="[contentBlockHeaderClass()]"
  >
    {{ title }}
  </component>
</template>

<script setup lang="ts">
const props = defineProps({
  headingType: {
    type: String,
    default: 'h2',
  },

  title: {
    type: String,
    required: true,
  },

  visualSize: {
    type: String,
    default: '',
  },
});

const options = ['h1', 'h2', 'h3', 'h4', 'h5', 'h6'];
const seoHeadingtype = computed(() => (options.includes(props.headingType) ? props.headingType : 'h2'));

const opmaakOptions: Record<string, string> = {
  h1: 'heading1',
  h2: 'heading2',
  h3: 'heading3',
  h4: 'heading4',
};

function contentBlockHeaderClass() {
  const size = props.visualSize ? props.visualSize : props.headingType;
  return opmaakOptions[size] || 'heading2';
}
</script>
