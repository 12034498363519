<template>
  <div class="AmountGuestsFilter">
    <div class="whenBlock">
      <div>
        <span v-t="'landing.filters.amount.adults'" />

        <span
          v-t="'landing.filters.amount.adultsAge'"
          class="q-ml-sm textLowImportance extraInfo"
        />
      </div>
      <div>
        <q-btn
          icon="icon-minus"
          class="iconButton"
          size="xs"
          @click="data.adults--"
          :disable="data.adults < 1"
        />
        <span v-text="data.adults" />
        <q-btn
          icon="icon-plus"
          class="iconButton"
          size="xs"
          @click="data.adults++"
        />
      </div>
    </div>

    <div
      v-if="!personsOnly"
      class="whenBlock"
    >
      <div v-t="'landing.filters.amount.bedrooms'" />
      <div>
        <q-btn
          icon="icon-minus"
          class="iconButton"
          size="xs"
          @click="data.bedrooms--"
          :disable="data.bedrooms < 1"
        />
        <span v-text="data.bedrooms" />
        <q-btn
          icon="icon-plus"
          class="iconButton"
          size="xs"
          @click="data.bedrooms++"
        />
      </div>
    </div>

    <div class="whenBlock">
      <div>
        <span v-t="'landing.filters.amount.children'" />

        <span
          v-t="'landing.filters.amount.childrenAge'"
          class="q-ml-sm textLowImportance extraInfo"
        />
      </div>
      <div>
        <q-btn
          icon="icon-minus"
          class="iconButton"
          size="xs"
          @click="data.children--"
          :disable="data.children < 1"
        />
        <span v-text="data.children" />
        <q-btn
          icon="icon-plus"
          class="iconButton"
          size="xs"
          @click="data.children++"
        />
      </div>
    </div>

    <div class="whenBlock">
      <div>
        <span v-t="'landing.filters.amount.babys'" />

        <span
          v-t="'landing.filters.amount.babysAge'"
          class="q-ml-sm textLowImportance extraInfo"
        />
      </div>
      <div>
        <q-btn
          icon="icon-minus"
          class="iconButton"
          size="xs"
          @click="data.babies--"
          :disable="data.babies < 1"
        />
        <span v-text="data.babies" />
        <q-btn
          icon="icon-plus"
          class="iconButton"
          size="xs"
          @click="data.babies++"
        />
      </div>
    </div>

    <q-checkbox
      dense
      v-if="!personsOnly"
      v-model="data.allowSofaBeds"
      color="secondary"
      :label="t('landing.filters.amount.sofaAccomodations')"
    />
  </div>
</template>

<script setup lang="ts">
import { useI18n } from 'vue-i18n';
import useStore from '~/store/store';

const props = defineProps({
  personsOnly: {
    type: Boolean,
    default: false,
  },

  useStoreData: {
    type: Boolean,
    default: true,
  },
});
const store = useStore();
const data = ref(props.useStoreData ? store.guestData : JSON.parse(JSON.stringify(store.guestData)));
const { t } = useI18n();
const emit = defineEmits(['update:modelValue']);

watch(
  () => data.value,
  (value) => {
    emit('update:modelValue', value);
  },
  { deep: true, immediate: true }
);
</script>

<style lang="scss" scoped>
@import '~/styles/variables.sass';

.AmountGuestsFilter {
  max-width: 560px;
}

.iconButton {
  padding: 4px !important;
  border-radius: 50px;
  margin: 0 10px;
  border: 2px solid $sea-blue-400;
}

.whenBlock {
  display: inline-flex;
  flex-direction: column;
  gap: 8px;
  width: 50%;
  margin-bottom: 24px;
}

@media (max-width: $screen-xs) {
  .whenBlock {
    padding-bottom: 5px;
    // border-bottom: 2px solid $sea-blue-300;
  }

  .extraInfo {
    display: none;
  }
}
</style>
