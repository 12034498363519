<template>
  <div>
    <LayoutHeader />
    <!-- 

      ERRROR PAGE

    -->

    <!-- <div class="pageContainer">Error</div> -->

    <div
      v-if="!isLoading"
      class="contentblocksPageContainer"
    >
      <ContentBlocksContainer :data="data" />
    </div>
    <LayoutFooter class="q-mt-xl" />
  </div>
</template>

<script setup lang="ts">
const data = ref<any>({});
const isLoading = ref(true);

data.value = await fetchData(`/api/page/2127`);
isLoading.value = false;
if (data.value?.metadata) {
  setMetaData(data.value.metadata);
}

if (process.server) {
  const event = useRequestEvent();
  setResponseStatus(event, 404);
}
</script>
